import  { useState } from "react";
import { useTranslation } from "react-i18next";

function useDashboard() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return { a11yProps, handleChange, setValue, value, t };
}

export default useDashboard;
