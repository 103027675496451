// api to get current user information

import NetworkApi from "./networkApi";
import { paths } from "./path";

export const getCurrentUserProfile = async (body) => {
  let token = localStorage.getItem("token");
  const headers = {
    Authorization: `${token}`,
  };
  if (!token) {
    return false;
  }
  try {
    const data = await NetworkApi.get(paths.currentUser, headers).then(
      (res) => {
        //  let entity= localStorage.getItem("LsEntitySnapshot");
        //  let department= localStorage.getItem("LsDepartmentSnapshot");
        // let permissions = JSON.parse(res?.data?.user?.permissions);
        //  let newEntity=permissions.entityAccess
        //   console.log("data: " + res.data.id , entity, department);
        return res;
      }
    );
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

// api to Forgot password for current user

export const forgotPassword = async (body) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const data = await NetworkApi.post(paths.forgotPasswordUrl, body, headers);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};
