import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useSettings from "./useSettings";
import { memo } from "react";
import { lazy } from "react";
import { Suspense } from "react";
import Loader from "../../components/loader/Loader";
import useGlobalPermissions from "../../useGlobalPermissions";
import "./index.css";
const UserManagement = lazy(() => import("./userManagement"));
const Account = lazy(() => import("./Account"));
const Header = lazy(() => import("../../components/header/Index"));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const { userData } = useGlobalPermissions();
  const { t } = useSettings();
  if (
    value === 1 &&
    (userData?.data?.type === "user" || userData?.data?.type === "guest")
  ) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{ p: 3 }}
            className="h-[75vh] flex items-center justify-center text-xl"
          >
            <b>Only admin can access to User Management</b>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function Settings() {
  const { a11yProps, handleChange, value, t } = useSettings();
  const { userData } = useGlobalPermissions();

  return (
    <div>
      <Suspense fallback={<Loader />}>
        <Header heading={t("userManagement.Settings")} subHeading=" " />
      </Suspense>

      <div className=" px-5  py-10 container-custom">
        <Box sx={{ width: "100%" }}>
          <Box className=" mb-3">
            <Tabs
              textColor="none"
              indicatorColor="none"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="text-black tabSections setting-tabs"
            >
              <Tab label={t("userManagement.Account")} {...a11yProps(0)} />
              <Tab
                label={t("userManagement.user_Management")}
                {...a11yProps(1)}
              />
              <Tab
                className="inactive"
                label={t("userManagement.Analytics_Settings")}
                {...a11yProps(2)}
              />
              <Tab
                className="inactive  "
                label={t("userManagement.Company")}
                {...a11yProps(3)}
              />
              <Tab
                className="inactive"
                label={t("userManagement.Plan")}
                {...a11yProps(4)}
              />
              <Tab
                className="inactive"
                label={t("userManagement.Activity")}
                {...a11yProps(5)}
              />
              <Tab
                className="inactive"
                label={t("userManagement.Notifications")}
                {...a11yProps(6)}
              />
            </Tabs>
          </Box>
          <div class="custom-border mx-6"></div>
          <TabPanel value={value} index={0}>
            <Suspense fallback={<Loader />}>
              <Account />
            </Suspense>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Suspense fallback={<Loader />}>
              <UserManagement />
            </Suspense>
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* {t("userManagement.comingSoon")} */}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {/* {t("userManagement.comingSoon")} */}
          </TabPanel>
          <TabPanel value={value} index={4}>
            {/* {t("userManagement.comingSoon")} */}
          </TabPanel>
          <TabPanel value={value} index={5}>
            {/* {t("userManagement.comingSoon")} */}
          </TabPanel>
          <TabPanel value={value} index={6}>
            {/* {t("userManagement.comingSoon")} */}
          </TabPanel>
        </Box>

      </div>
    </div>
  );
}

export default memo(Settings);
