import { useMutation, useQuery } from 'react-query';
import {forgotPassword, getCurrentUserProfile} from '../api/user'
import { toast } from 'react-toastify';


export const useCurrentUserProfile = () => {
    const { data, isLoading, error, refetch ,isFetched} = useQuery(
      ["Current-User"],
      getCurrentUserProfile,
      {
        enabled: true,
      }
    );
    return { data, isLoading, error, refetch,isFetched };
  };


  export const useforgotPassword = () => {
  
    const { mutate, isLoading, error } = useMutation(
      ["forgot-password"],
      (userInfo) => forgotPassword(userInfo),
      {
        onSuccess: (data) => {
          // Show a success toast
          toast.success(data?.message, {
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        onError: (error) => {
          // Show an error toast
          toast.error(data?.message, {
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
      }
    );
  
    return { mutate, isLoading, error };
  };
  