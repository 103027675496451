import React from "react";
import { useLogin } from "../hooks/Auth";
import * as Yup from "yup";
import { useforgotPassword } from "../hooks/user";

function useLoginPage() {
  const { mutate: login, data, isLoading: loginLoading } = useLogin();
  const {mutate:forgotPassword}=useforgotPassword()
  const signUpSchema = Yup.object({
    email: Yup.string()
      .trim("The email cannot include spaces.")
      .strict(true).matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email address."
      )
      .email("Please enter a valid email address.")
      .required("Please enter your email."),
    password: Yup.string().required("Please enter your password."),
  });
  const initialValues = {
    email: "",
    password: "",
  };
  return { login, signUpSchema, initialValues,forgotPassword };
}

export default useLoginPage;
