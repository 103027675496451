import { json } from "react-router-dom";
import NetworkApi from "./networkApi";
import { paths } from "./path";

export const getFilterListingForDataExplorer = async () => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  const option = {
    withPermission: true,
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const { data } = await NetworkApi.get(
      paths.filterListingUrlForDataExplorer,
      option
    );
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }

    throw error;
  }
};

export const getDataForDataExplorer = async (body) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const { data } = await NetworkApi.get(
      paths.dataExpolorerURL(body),
      headers
    );
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }

    throw error;
  }
};

export const getDataForDataExplorerPieChart = async (body) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const { data } = await NetworkApi.get(
      paths.dataExplorerPieChartURL(body),
      headers
    );

    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }

    throw error;
  }
};
