import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import Dashboard from "../pages/dashboard/Index";
import ForgotPassword from "../pages/ForgotPassword";
import Login from "../pages/login";
import SetPassword from "../pages/setPassword";
import Settings from "../pages/settings";
import DataExplorer from "../pages/dataExplorer";
import AdvancedAnalytics from "../pages/advancedAnalytics";
import useGlobalPermissions from "../useGlobalPermissions";
import HrQuestions from "../pages/advancedAnalytics/HrQuestions";
import Reports from "../pages/reports/myReports";
import SharedWithMe from "../pages/reports/sharedWithMe";
import ViewReport from "../pages/reports/viewReport";
const AppRoutes = () => {
  const { moduleAccessPermissions, dataAccessPermissions } =
    useGlobalPermissions();

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <ProtectedRoutes
              moduleAccessPermissions={moduleAccessPermissions}
              dataAccessPermissions={dataAccessPermissions}
              Component={Dashboard}
            />
          }
        />

        {moduleAccessPermissions["Explorer"] && (
          <Route
            path="/dataexplorer"
            element={<ProtectedRoutes Component={DataExplorer} />}
          />
        )}
        {moduleAccessPermissions["Advanced Analytics"] && (
          <>
            <Route
              path="/advancedanalytics/deepanalysis"
              element={<ProtectedRoutes Component={AdvancedAnalytics} />}
            />
            <Route
              path="/advancedanalytics"
              element={<ProtectedRoutes Component={HrQuestions} />}
            />
          </>
        )}
        <Route
          path="/settings"
          element={<ProtectedRoutes Component={Settings} />}
        />
        <Route
          path="/myreports"
          element={<ProtectedRoutes Component={Reports} />}
        />
        <Route
          path="/SharedWithMe"
          element={<ProtectedRoutes Component={SharedWithMe} />}
        />
        <Route
          path="/myreports/:viewID"
          element={<ProtectedRoutes Component={ViewReport} />}
        />
        <Route
          path="/SharedWithMe/:viewID"
          element={<ProtectedRoutes Component={ViewReport} />}
        />
        <Route path="/login/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/setPassword" element={<SetPassword />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
};
export default AppRoutes;
