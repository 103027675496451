import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useConfirmInvites,
  useDeleteMember,
  usePendingInvite,
  useResendInvite,
  useScopeListing,
  useUpdateUserDetails,
  useUserInvite,
} from "../../hooks/Admin";
import * as Yup from "yup";
import { useCurrentUserProfile } from "../../hooks/user";
import useGlobalPermissions from "../../useGlobalPermissions";
import { useNavigate } from "react-router-dom";
function useSettings() {
  
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [checkScope, setCheckScope] = useState(false);

  //invite the user to the organization is started
  const { data: ScopeList } = useScopeListing();
  const [entity, setEntity] = useState([]);
  const [entityOpen, setEntityOpen] = useState(false);
  const [isSelectAllEntity, setIsSelectAllEntity] = useState(false);

  const entityList = ScopeList?.Other?.EntityList;
  const handleEntityChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setEntity([...entity, value]);
    } else {
      let res = entity.filter((e) => e !== value);
      setEntity(res);
    }
  };
  const selectAllEntity = (e) => {
    const { checked } = e.target;
    setIsSelectAllEntity(!isSelectAllEntity);
    if (checked) {
      setEntity(entityList?.map((item) => item.entity_name));
    } else {
      setEntity([]);
    }
  };

  useEffect(() => {
    if (entity?.length === entityList?.length) {
      setIsSelectAllEntity(true);
    } else {
      setIsSelectAllEntity(false);
    }
  }, [entity]);
  // starting for people scope
  const [peopleScopeOpen, setPeopleScopeOpen] = useState(false);
  const [isSelectAllDepartment, setIsSelectAllDepartment] = useState(false);
  const [department, setDepartment] = useState([]);
  const departmentList = ScopeList?.Other?.DepartmentList;

  const handleDepartmentChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setDepartment([...department, value]);
    } else {
      let res = department.filter((e) => e !== value);
      setDepartment(res);
    }
  };
  const selectAllDepartment = (e) => {
    const { checked } = e.target;
    setIsSelectAllDepartment(!isSelectAllDepartment);
    if (checked) {
      setDepartment(departmentList?.map((item) => item.department_name));
    } else {
      setDepartment([]);
    }
  };

  useEffect(() => {
    if (department?.length === departmentList?.length) {
      setIsSelectAllDepartment(true);
    } else {
      setIsSelectAllDepartment(false);
    }
  }, [department]);

  //starting for Module Access

  const [moduleAccessOpen, setModuleAccessOpen] = useState(false);
  const [modules, setModules] = useState([]);
  const [isSelectAllModules, setIsSelectAllModules] = useState(false);
  const moduleList = ["Dashboard", "Explorer", "Advanced Analytics"];
  const handleModuleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setModules([...modules, value]);
    } else {
      let res = modules.filter((e) => e !== value);
      setModules(res);
    }
  };
  const selectAllModules = (e) => {
    const { checked } = e.target;
    setIsSelectAllModules(!isSelectAllModules);
    if (checked) {
      setModules(moduleList?.map((item) => item));
    } else {
      setModules([]);
    }
  };

  useEffect(() => {
    if (modules?.length === moduleList?.length) {
      setIsSelectAllModules(true);
    } else {
      setIsSelectAllModules(false);
    }
  }, [modules]);
  //starting for Data Access
  const [DataAccessOpen, setDataAccessOpen] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [isSelectAllMetrics, setIsSelectAllMetrics] = useState(false);
  const metricsList = ScopeList?.Metrics ? Object.keys(ScopeList?.Metrics) : [];

  const handleMetricsChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setMetrics([...metrics, value]);
    } else {
      let res = metrics.filter((e) => e !== value);
      setMetrics(res);
    }
  };
  const selectAllMetrics = (e) => {
    const { checked } = e.target;
    setIsSelectAllMetrics(!isSelectAllMetrics);
    if (checked) {
      setMetrics(metricsList?.map((item) => item));
    } else {
      setMetrics([]);
    }
  };
  // console.log("metrics", metrics, modules);
  useEffect(() => {
    if (metrics?.length === metricsList?.length) {
      setIsSelectAllMetrics(true);
    } else {
      setIsSelectAllMetrics(false);
    }
  }, [metrics]);

  // starting for role dropdown
  const [role, setRole] = useState("user");
  const [roleOpen, setRoleOpen] = useState(false);
  const handleClickAway = (val) => {
    if (val === "entity") {
      setEntityOpen(false);
    } else if (val === "peopleScope") {
      setPeopleScopeOpen(false);
    } else if (val === "dataAccess") {
      setDataAccessOpen(false);
    } else if (val === "moduleAccess") {
      setModuleAccessOpen(false);
    } else if (val === "role") {
      setRoleOpen(false);
    }
  };
  // console.log("Access scope",entity,department,modules,metrics);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const { mutate: userInvite, isLoading } = useUserInvite();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    type: "user",
    job_title: "",
  };
  const signUpSchema = Yup.object({
    firstName: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain letters."
      )
      .trim("The first name cannot include spaces.")
      .strict(true)
      .min(2, "First name must be at least 2 characters.")
      .max(25, "First name must be at most 25 characters.")
      .required("Please enter your first name."),
    lastName: Yup.string()
      .trim("The last name cannot include spaces.")
      .strict(true)
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain letters."
      )
      .min(2, "Last name must be at least 2 characters.")
      .max(25, "Last name must be at most 25 characters.")
      .required("Please enter your last name."),
    email: Yup.string()
      .trim("The email cannot include spaces.")
      .strict(true)
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email must be a valid email."
      )
      .ensure()
      .email("Email must be a valid email.")
      .required("Please enter your email."),
    type: Yup.string().required("Please enter user role."),
    job_title: Yup.string()
      .trim("The job title cannot include spaces.")
      .strict(true)
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Job title can only contain letters."
      )
      .min(2, "Job title must be at least 2 characters.")
      .max(25, "Job title must be at most 25 characters.")
      .required("Please enter your job title."),
  });

  // starting for confirm users list
  const { refetch, data: confirmUserData } = useConfirmInvites();

  // starting for edit user
  const [DataForEdit, setDataForEdit] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const { mutate: userUpdate } = useUpdateUserDetails();

  const handleEditModel = (data) => {
    setShowEditModal(true);
    setDataForEdit(data);
  };

  // starting for pending invites list
  const { data: pendingUserData } = usePendingInvite();
  const { mutate: ResendInvite } = useResendInvite();
  const [deleteId, setDeleteId] = useState("");
  const { mutate: deleteMember } = useDeleteMember();
  const { userData } = useGlobalPermissions();
  let userType = userData?.data?.type;
  const handleResendInvite = (id) => {
    ResendInvite(id);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDeleteConfirm = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };
  const navigate = useNavigate();
  const handleDeleteMember = () => {
    if (userData?.data?.id == deleteId) {
      deleteMember(deleteId);
      localStorage.clear();
      navigate("/", { replace: true });
    } else {
      deleteMember(deleteId);
    }
    setShowModal(false);
  };

  // starting for change password
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { data: currentProfile, isLoading: currentUserLoading } =
    useCurrentUserProfile();

  //starting for account page

  const accountSchema = Yup.object({
    firstName: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "First name can only contain letters."
      )
      .trim("The first name cannot include spaces.")
      .strict(true)
      .min(2, "First name must be at least 2 characters.")
      .max(25, "First name must be at most 25 characters.")
      .required("Please enter your first name."),
    lastName: Yup.string()
      .trim("The last name cannot include spaces.")
      .strict(true)
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Last name can only contain letters."
      )
      .min(2, "Last name must be at least 2 characters.")
      .max(25, "Last name must be at most 25 characters.")
      .required("Please enter your last name."),
    email: Yup.string()
      .trim("The email cannot include spaces.")
      .strict(true)
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email must be a valid email."
      )
      .ensure()
      .email("Email must be a valid email.")
      .required("Please enter your email."),
    type: Yup.string().required("Please enter user role."),
    job_title: Yup.string()
      .trim("The job title cannot include spaces.")
      .strict(true)
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Job title can only contain letters."
      )
      .min(2, "Job title must be at least 2 characters.")
      .max(25, "Job title must be at most 25 characters.")
      .required("Please enter your job title."),
  });
  //starting for account page
  const accountValues = {
    firstName: `${currentProfile?.data?.first_name}`,
    lastName: `${currentProfile?.data?.last_name}`,
    email: `${currentProfile?.data?.personal_email}`,
    type: "user",
    password: `${currentProfile?.data?.password}`,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return {
    a11yProps,
    handleChange,
    setValue,
    value,
    t,
    userType,
    showInviteModal,
    setShowInviteModal,
    checkScope,
    setCheckScope,
    initialValues,
    signUpSchema,
    userInvite,
    confirmUserData,
    showEditModal,
    setShowEditModal,
    signUpSchema,
    userUpdate,
    DataForEdit,
    handleEditModel,
    pendingUserData,
    handleResendInvite,
    handleDeleteConfirm,
    deleteMember,
    handleDeleteMember,
    showChangePasswordModal,
    setShowChangePasswordModal,
    currentProfile,
    accountSchema,
    accountValues,
    showModal,
    setShowModal,
    ScopeList,
    handleEntityChange,
    selectAllEntity,
    isSelectAllEntity,
    entityList,
    entity,
    setEntity,
    setEntityOpen,
    entityOpen,
    handleClickAway,
    peopleScopeOpen,
    setPeopleScopeOpen,
    department,
    setDepartment,
    isSelectAllDepartment,
    setIsSelectAllDepartment,
    selectAllDepartment,
    departmentList,
    handleDepartmentChange,
    DataAccessOpen,
    metrics,
    setMetrics,
    setDataAccessOpen,
    selectAllMetrics,
    isSelectAllMetrics,
    metricsList,
    handleMetricsChange,
    moduleAccessOpen,
    setModuleAccessOpen,
    modules,
    setModules,
    moduleList,
    handleModuleChange,
    isSelectAllModules,
    selectAllModules,
    roleOpen,
    setRoleOpen,
    role,
    setRole,
    userData
  };
}

export default useSettings;
