import React, { useState } from "react";
import { useUserConfirm } from "../hooks/Auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import useSetPasswordPage from "./useSetPasswordPage";
import { memo } from "react";

function SetPassword() {
  const { confirmPassword, signUpSchema, initialValues } = useSetPasswordPage();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        confirmPassword(values);
        action.resetForm();
      },
    });
  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-gray-200">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
        <h1 className="text-2xl font-semibold text-center text-blue-600 ">
          Set Password
        </h1>
        <form onSubmit={handleSubmit} className="mt-6">
          <div className="mb-2">
            <label
              htmlFor="text"
              className="block text-sm font-semibold text-gray-800"
            >
              New password
            </label>
            <input
              value={values.password}
              onChange={handleChange}
              name="password"
              type="password"
              autoComplete="off"
              onBlur={handleBlur}
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            {errors.password && touched.password ? (
              <p className="text-red-600 text-sm">{errors.password}</p>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="text"
              className="block text-sm font-semibold text-gray-800"
            >
              Confirm Password
            </label>
            <input
              value={values.cPassword}
              onChange={handleChange}
              name="cPassword"
              type="password"
              autoComplete="off"
              onBlur={handleBlur}
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            {errors.cPassword && touched.cPassword ? (
              <p className="text-red-600 text-sm">{errors.cPassword}</p>
            ) : null}
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-600 rounded-md hover:text-blue-500 focus:outline-none focus:text-blue-500"
            >
              Set Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default memo(SetPassword);
