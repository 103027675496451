import NetworkApi from "./networkApi";
import { paths } from "./path";

export const getDataForBasicAdvAnalytics = async (body) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const { data } = await NetworkApi.get(paths.basicAdvAnalyticsURL(body), headers);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized"){
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};
export const getDataForGoFurtherAdvAnalytics= async (body) => {
    const headers = {
      Authorization: `${localStorage.getItem("token")}`,
    };
    try {
      const { data } = await NetworkApi.get(paths.goFurtherAdvAnalyticsURL(body), headers);
      return data;
    } catch (error) {
      if (error?.response?.statusText === "Unauthorized"){
      localStorage.clear();
      window.location.pathname = "/login";
    }
      throw error;
    }
  };

  export const getDataForSummaryAdvAnalytics=async (body) => {
    const headers = {
      Authorization: `${localStorage.getItem("token")}`,
    };
    try {
      const { data } = await NetworkApi.get(paths.SummaryAdvAnalyticsURL(body), headers);
      return data;
    } catch (error) {
      if (error?.response?.statusText === "Unauthorized"){
      localStorage.clear();
      window.location.pathname = "/login";
    }
      throw error;
    }
  };