import { paths } from "./path";
import axios from "axios";
import NetworkApi from "./networkApi";

// api for scope listing

export const getScopeListing = async (id) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const { data } = await NetworkApi.get(paths.listingUrlForScope, headers);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

//api for delete confirm user

export const getDeleteUser = async (id) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const data = await NetworkApi.delete(paths.deleteUserUrl(id), headers);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

// Resend invite to the pending user

export const getResendInvite = async (id) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const data = await NetworkApi.get(paths.resendInviteUrl(id), headers);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};
// api to invite the user to the organization

export const getUserInvite = async (body) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const data = await NetworkApi.post(paths.inviteUserUrl, body, headers).then(
      (res) => {
        console.log(res.data);
        return res;
      }
    );
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

// api to get pending user list

export const getPendingInvites = async () => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const data = await NetworkApi.get(paths.pendingInvitesUrl, headers).then();

    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

// api to get confirm user list

export const getConfirmInvites = async () => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const data = await NetworkApi.get(paths.confirmInvitesUrl, headers).then();
    // console.log("confirm invites", data);

    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

export const getUpdateUser = async (data) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  const { firstName, lastName, type, id, permissions, job_title } = data;
  const body = {
    first_name: firstName,
    last_name: lastName,
    type,
    job_title,
    permissions,
  };

  try {
    const data = await NetworkApi.put(paths.updateUserUrl(id), body, headers);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

//api to get all users in organization

export const getAllUsers = async () => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const { data } = await fetch(
      `${paths.getAllUsersUrl}api/admin/allEmployees`,
      { headers }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        return data;
      });
    console.log(data);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

//api to get perticular users information

export const getUserProfile = async () => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const { data } = await axios(`${URL}api/users/get/profile`, {
      headers,
    }).then((data) => {
      console.log(data);
      return data;
    });
    console.log(data);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

export const getUpdateUserType = async (data) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  const { type, id } = data;
  const body = { type };

  // console.log(type, id);

  try {
    const { data } = await axios
      .put(`${URL}api/admin/updateEmployee/${id}`, body, {
        headers,
      })
      .then((data) => {
        return data;
      });
    console.log(data);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};
