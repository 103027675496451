import React, { useEffect, useState } from "react";
import { useCurrentUserProfile } from "./hooks/user";

function useGlobalPermissions() {
  const [userData, setUserData] = useState(
    localStorage.getItem("permissions")
  );
  const { data, isLoading: userProfileLoading } = useCurrentUserProfile();
  useEffect(() => {
    if (!userProfileLoading) {
      setUserData(data);

      // window.location.reload(true)
    }
  }, [data]);
  const [userProfile, setUserProfile] = useState({});
  const [dataAccessPermissions, setDataAccessPermissions] = useState({
    Headcount: false,
    Recruitment: false,
    Attrition: false,
    Compensation: false,
    Absenteism: false,
  });
  const [moduleAccessPermissions, setModuleAccessPermissions] = useState({
    Dashboard: true,
    Explorer: false,
    "Advanced Analytics": false,
  });
  useEffect(() => {
    if (!userProfileLoading && userData?.data?.permissions !== undefined) {
      //   setUserProfile(JSON.parse(userData?.data?.permissions));
      let dataAccess = Object.keys(dataAccessPermissions).map((permission) => {
        if (
          userData?.data?.permissions &&
          userData?.data?.permissions?.dataAccess.includes(
            permission
          )
        ) {
          return [permission, true];
        }
        return [permission, false];
      });
      let moduleAccess = Object.keys(moduleAccessPermissions).map(
        (permission) => {
          if (
            userData?.data?.permissions &&
            userData?.data?.permissions?.moduleAccess.includes(
              permission
            )
          ) {
            return [permission, true];
          }
          return [permission, false];
        }
      );
      setModuleAccessPermissions(Object.fromEntries(moduleAccess));
      setDataAccessPermissions(Object.fromEntries(dataAccess));
    }
  }, [userData]);

  return {
    userData,
    userProfileLoading,
    userProfile,
    dataAccessPermissions,
    moduleAccessPermissions,
  };
}

export default useGlobalPermissions;
