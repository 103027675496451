import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layout/Index";
import AppLogout from "../AppLogout";

function ProtectedRoutes({
  Component,
  moduleAccessPermissions,
  dataAccessPermissions,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    let storage = localStorage.getItem("token");
    if (!storage) {
      navigate("/login");
    }
  });
  return (
    <AppLogout>
      <Layout>
        <Component
          moduleAccessPermissions={moduleAccessPermissions}
          dataAccessPermissions={dataAccessPermissions}
        />
      </Layout>
    </AppLogout>
  );
}

export default ProtectedRoutes;
