import React, { useEffect, useState } from "react";
import {
  MdOutlineDashboard,
  MdOutlineManageAccounts,
  MdGroups,
} from "react-icons/md";
import { HiHome } from "react-icons/hi";
import { FaFolderOpen, FaChartBar } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { BsChevronDown, BsServer } from "react-icons/bs";
import { Translation, useTranslation } from "react-i18next";
import "../../i18n";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/icons/logo.svg";
import logoSmall from "../../assets/icons/logo-small.svg";
import menu from "../../assets/icons/Menu.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Report } from "../../assets/icons/report.svg";
import { ReactComponent as Company } from "../../assets/icons/company.svg";
import { ReactComponent as Analytics } from "../../assets/icons/analytics.svg";
import { ReactComponent as Explorer } from "../../assets/icons/explorer.svg";
import { ReactComponent as Management } from "../../assets/icons/management.svg";
import { ReactComponent as Info } from "../../assets/icons/Information.svg";
// import info from "../../assets/icons/Information.svg";
import useGlobalPermissions from "../../useGlobalPermissions";
const Sidebar = ({ setOpen, open, toggleSidebar }) => {
  const { moduleAccessPermissions } = useGlobalPermissions();
  const path = useLocation();
  const MenusData = [
    {
      title: <Translation>{(t) => <>{t("Sidebar.Dashboard")}</>}</Translation>,
      access: moduleAccessPermissions["Dashboard"],
      src: "",
      icon: <Dashboard />,
      // icon: dashboard,
      subMenuOpen: false,
      hide: true,
      isActive: path?.pathname === "/" ? true : false,
      subMenus: [
        {
          title: (
            <Translation>{(t) => <>{t("Sidebar.Overview")}</>}</Translation>
          ),
          src: "/",
          cName: "sub-nav",
          isActive: path?.pathname === "/" ? true : false,
        },
        {
          title: (
            <Translation>
              {(t) => <>{t("Sidebar.My_dashboards")}</>}
            </Translation>
          ),
          src: "",
          cName: "sub-nav",
          subTitle: (
            <Translation>{(t) => <>{t("Sidebar.coming")}</>}</Translation>
          ),
        },
      ],
    },
    {
      title: <Translation>{(t) => <>{t("Sidebar.Reports")}</>}</Translation>,
      access: true,
      src: "",
      subMenuOpen: false,
      icon: <Report />,
      hide: true,
      isActive:
        path?.pathname === "/myreports" || path?.pathname === "/sharedwithme"
          ? true
          : false,
      subMenus: [
        {
          title: (
            <Translation>{(t) => <>{t("Sidebar.My_Reports")}</>}</Translation>
          ),
          src: "/myreports",
          cName: "sub-nav",
          isActive: path?.pathname === "/myreports" ? true : false,
        },
        {
          title: (
            <Translation>
              {(t) => <>{t("Sidebar.Shared_With_Me")}</>}
            </Translation>
          ),
          src: "/sharedwithme",
          cName: "sub-nav",
          isActive: path?.pathname === "/sharedwithme" ? true : false,
        },
      ],
    },
    {
      title: <Translation>{(t) => <>{t("Sidebar.My_Company")}</>}</Translation>,
      access: true,
      src: "",
      gap: false,
      icon: <Company />,
      subTitle: <Translation>{(t) => <>{t("Sidebar.coming")}</>}</Translation>,
      subMenuOpen: false,
      hide: true,
      subMenus: [
        {
          title: (
            <Translation>{(t) => <>{t("Sidebar.Employees")}</>}</Translation>
          ),
          src: "",
          cName: "sub-nav",
        },
        {
          title: (
            <Translation>{(t) => <>{t("Sidebar.Structure")}</>}</Translation>
          ),
          src: "",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: (
        <Translation>
          {(t) => <>{t("Sidebar.Advanced_Analytics")}</>}
        </Translation>
      ),
      access: moduleAccessPermissions["Advanced Analytics"],
      src: "/advancedanalytics",
      subMenuOpen: false,
      icon: <Analytics />,
      hide: true,
      isActive: path?.pathname === "/advancedanalytics" ? true : false,
    },
    {
      title: (
        <Translation>{(t) => <>{t("Sidebar.Data_Explorer")}</>}</Translation>
      ),
      access: moduleAccessPermissions["Explorer"],
      src: "",
      icon: <Explorer />,
      subMenuOpen: false,
      hide: true,
      isActive: path?.pathname === "/dataexplorer" ? true : false,
      subMenus: [
        {
          title: (
            <Translation>
              {(t) => <>{t("Sidebar.Data_Explorer")}</>}
            </Translation>
          ),
          src: "/dataexplorer",
          cName: "sub-nav",
          isActive: path?.pathname === "/dataexplorer" ? true : false,
        },
        {
          title: (
            <Translation>
              {(t) => <>{t("Sidebar.Data_Extractor")}</>}
            </Translation>
          ),
          src: "",
          cName: "sub-nav",
          subTitle: (
            <Translation>{(t) => <>{t("Sidebar.coming")}</>}</Translation>
          ),
        },
      ],
    },
    {
      title: (
        <Translation>{(t) => <>{t("Sidebar.Data_Management")}</>}</Translation>
      ),
      access: true,
      src: "",
      subMenuOpen: false,
      icon: <Management />,
      hide: true,
      subTitle: <Translation>{(t) => <>{t("Sidebar.coming")}</>}</Translation>,
    },
  ];
  const [Menus, setMenus] = useState(MenusData);
  const handleSubmenu = (menu, index) => {
    let updatedData = Menus.map((item, ind) => {
      if (ind === index) {
        return { ...item, subMenuOpen: !item.subMenuOpen };
      } else {
        return item;
      }
    });
    setMenus(updatedData);
  };

  useEffect(() => {
    setMenus([
      {
        title: (
          <Translation>{(t) => <>{t("Sidebar.Dashboard")}</>}</Translation>
        ),
        access: moduleAccessPermissions["Dashboard"],
        src: "",
        icon: (
          <Dashboard
            className={` ${path?.pathname === "/" ? "activeMenu" : "deactiveMenu"
              }`}
          />
        ),
        // icon: dashboard,
        subMenuOpen: false,
        hide: true,
        isActive: path?.pathname === "/" ? true : false,
        subMenus: [
          {
            title: (
              <Translation >{(t) => <>{t("Sidebar.Overview")}</>}</Translation>
            ),
            src: "/",
            cName: "sub-nav",
            isActive: path?.pathname === "/" ? true : false,
          },
          {
            title: (
              <Translation>
                {(t) => <>{t("Sidebar.My_dashboards")}</>}
              </Translation>
            ),
            src: "/",
            cName: "sub-nav",
            subTitle: (
              <Translation>{(t) => <>{t("Sidebar.coming")}</>}</Translation>
            ),
          },
        ],
      },
      {
        title: <Translation>{(t) => <>{t("Sidebar.Reports")}</>}</Translation>,
        access: true,
        src: "",
        subMenuOpen: false,
        icon: (
          <Report
            className={`${path?.pathname === "/myreports" ||
              path?.pathname === "/sharedwithme"
              ? "activeMenu"
              : "deactiveMenu"
              }`}
          />
        ),
        hide: true,
        isActive:
          path?.pathname === "/myreports" || path?.pathname === "/sharedwithme"
            ? true
            : false,
        subMenus: [
          {
            title: (
              <Translation>{(t) => <>{t("Sidebar.My_Reports")}</>}</Translation>
            ),
            src: "/myreports",
            cName: "sub-nav",
            isActive: path?.pathname === "/myreports" ? true : false,
          },
          {
            title: (
              <Translation>
                {(t) => <>{t("Sidebar.Shared_With_Me")}</>}
              </Translation>
            ),
            src: "/sharedwithme",
            cName: "sub-nav",
            isActive: path?.pathname === "/sharedwithme" ? true : false,
          },
        ],
      },
      {
        title: (
          <Translation>{(t) => <>{t("Sidebar.My_Company")}</>}</Translation>
        ),
        access: true,
        src: "",
        gap: false,
        icon: <Company />,
        subTitle: (
          <Translation>{(t) => <>{t("Sidebar.coming")}</>}</Translation>
        ),
        subMenuOpen: false,
        hide: true,
        subMenus: [
          {
            title: (
              <Translation>{(t) => <>{t("Sidebar.Employees")}</>}</Translation>
            ),
            src: "",
            cName: "sub-nav",
          },
          {
            title: (
              <Translation>{(t) => <>{t("Sidebar.Structure")}</>}</Translation>
            ),
            src: "",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: (
          <Translation>
            {(t) => <>{t("Sidebar.Advanced_Analytics")}</>}
          </Translation>
        ),
        access: moduleAccessPermissions["Advanced Analytics"],
        src: "/advancedanalytics",
        subMenuOpen: false,
        icon: (
          <Analytics
            className={`${path?.pathname === "/advancedanalytics"
              ? "activeMenu"
              : "deactiveMenu"
              }`}
          />
        ),
        hide: true,
        isActive: path?.pathname === "/advancedanalytics" ? true : false,
      },
      {
        title: (
          <Translation>{(t) => <>{t("Sidebar.Data_Explorer")}</>}</Translation>
        ),
        access: moduleAccessPermissions["Explorer"],
        src: "",
        icon: (
          <Explorer
            className={`${path?.pathname === "/dataexplorer" ? "activeMenu" : "deactiveMenu"
              }`}
          />
        ),
        subMenuOpen: false,
        hide: true,
        isActive: path?.pathname === "/dataexplorer" ? true : false,
        subMenus: [
          {
            title: (
              <Translation>
                {(t) => <>{t("Sidebar.Data_Visualization")}</>}
              </Translation>
            ),
            src: "/dataexplorer",
            cName: "sub-nav",
            isActive: path?.pathname === "/dataexplorer" ? true : false,
          },
          {
            title: (
              <Translation>
                {(t) => <>{t("Sidebar.Data_Extractor")}</>}
              </Translation>
            ),
            src: "",
            cName: "sub-nav",
            subTitle: (
              <Translation>{(t) => <>{t("Sidebar.coming")}</>}</Translation>
            ),
          },
        ],
      },
      {
        title: (
          <Translation>
            {(t) => <>{t("Sidebar.Data_Management")}</>}
          </Translation>
        ),
        access: true,
        src: "",
        subMenuOpen: false,
        icon: <Management />,
        hide: true,
        subTitle: (
          <Translation>{(t) => <>{t("Sidebar.coming")}</>}</Translation>
        ),
      },
    ]);
  }, [moduleAccessPermissions, path?.pathname]);
  return (
    <>
      <div
        className={`flex items-end justify-star fixed z-40 ${open ? "w-[300px]" : "w-[71px]"
          }  border-r-[1px] border-gray-300 `}
      >
        <div
          className={` ${open ? "w-[100vw] px-4" : " bg-transparent"
            }  bg-white h-screen relative duration-500 w-full `}
        >
          <div className="h-24	logo-main items-center flex w-full">
            <Link
              to="/"
              className={` ${open ? "justify-start" : "justify-center "
                }  flex w-full `}
            >
              {open && (
                <img src={logo} alt="staffDeskLogo" className="w-[60%] " />
              )}
              {!open && (
                <img
                  src={logoSmall}
                  alt="staffDeskLogo"
                  className="w-[24px] "
                />
              )}
            </Link>
            <button
              className={`absolute duration-300  ${open
                ? "w-[26px] h-7 text-4xl right-0"
                : "w-[54px] h-auto text-2xl left-[50%] -translate-x-2/4  bg-transparent"
                }`}
              onClick={toggleSidebar}
              role="navigation"
            >
              <img
                src={menu}
                alt="toggleIcon"
                title={`${open ? "Hide navigation bar" : "Show navigation bar"
                  }`}
                className={` bg-[#dddddd]  py-[12px] px-[3px]  h-[37px] rounded-sm img-nav  ${open ? "rotate-180 w-[26px]" : "rotate-0 w-[54px]"
                  }`}
              // className={`${open ? "w-5 h-6" : "w-3 h-4 "}`}
              />
            </button>
          </div>
          <ul className="pt-5 flex flex-col items-baseline w-full sidebar">
            {Menus?.map((Menu, index) => {
              if (Menu.access) {
                return (
                  <React.Fragment key={index}>
                    {Menu.hide && (
                      <Link
                        key={index}
                        to={Menu.src}
                        className="underline decoration-transparent w-full"
                      >
                        <li
                          className={`flex cursor-pointer bg-grey-transparent font-poppins  items-center gap-x-4 tooltip 
                          ${Menu.gap ? "mt-9" : "mt-3"} ${open
                              ? "p-2 w-full rounded-md"
                              : "py-2 justify-center "
                            }  ${Menu.isActive ? "text-[#0A52A7]" : "text-black"
                            }`}
                          onClick={() => handleSubmenu(Menu, index)}
                        >
                          {!open && (
                            <span class="tooltiptext">{Menu.title}</span>
                          )}
                          {Menu.icon ? (
                            <>
                              {/* <img src={Menu.icon}
                              className={` ${open ? "w-[8%]" : "w-[32%]"
                                }`} /> */}
                              {Menu.icon}
                            </>
                          ) : (
                            <MdOutlineDashboard />
                          )}
                          {open && (
                            <span
                              onClick={() => handleSubmenu(Menu, index)}
                              className={`flex-1   font-bold font-15 flex justify-between flex-wrap pr-1 items-center`}
                            >
                              <span className={`flex justify-between `}>
                                {" "}
                                {open ? Menu.title : ""}
                                {Menu.subTitle ? (
                                  <Info className="pl-2 w-[20px]" />
                                ) : (
                                  ""
                                )}
                              </span>
                              <span>
                                {Menu.subMenus && (
                                  <BsChevronDown
                                    // onClick={() => setSubMenuOpen(!subMenuOpen)}
                                    onClick={() => handleSubmenu(Menu, index)}
                                    className={`${Menu.subMenuOpen && "rotate-180"
                                      }`}
                                  />
                                )}
                              </span>
                            </span>
                          )}
                        </li>
                        <div>
                          {Menu.subMenus && Menu.subMenuOpen && open && (
                            <ul className={`${open ? "ml-10" : ""}`}>
                              {Menu.subMenus?.map((subMenuItem, idx) => (
                                <Link
                                  to={subMenuItem.src}
                                  key={idx}
                                  className="underline decoration-transparent "
                                >
                                  <li
                                    key={idx}
                                    className={`flex font-poppins cursor-pointer text-center font-normal  py-1 text-hover   ${open ? "text-sm pl-2" : "text-xs pl-1"
                                      } ${subMenuItem?.isActive
                                        ? "text-[#0A52A7]"
                                        : "text-black"
                                      }`}
                                  >
                                    {subMenuItem.title}{" "}
                                    {subMenuItem.subTitle ? (
                                      <Info className="pl-2 w-[20px]" />
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                </Link>
                              ))}
                            </ul>
                          )}
                        </div>
                      </Link>
                    )}
                  </React.Fragment>
                );
              } else {
                return false;
              }
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
