import axios from "axios";
import NetworkApi from "./networkApi";
import { paths } from "./path";

const URL = import.meta.env.VITE_REACT_APP_API_BASE_URL;

// api for login

export const login = async (body) => {
  try {
    const data = await NetworkApi.post(paths.LoginUrl, body).then((res) => {
      localStorage.setItem("token", res?.data?.token);
      localStorage.setItem("type", res?.data?.user?.type);
      localStorage.setItem(
        "LsIsDistinctMonths",
        new Date().getMonth() >= 2 ? true : false
      );
      localStorage.setItem(
        "permissions",
        JSON.stringify(res.data.user.permissions)
      );
      let permissions = res?.data?.user?.permissions;
      localStorage.setItem(
        "LsDepartmentSnapshot",
        JSON.stringify(permissions.peopeleScope)
      );
      localStorage.setItem(
        "LsEntitySnapshot",
        JSON.stringify(permissions.entityAccess)
      );

      return res;
    });
    return data;
  } catch (e) {
    throw e;
  }
};

// api to change password for current user

export const changePassword = async (body) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const data = await NetworkApi.post(
      paths.changePasswordUrl,
      body,
      headers
    ).then((res) => {
      // console.log(res.data);
    });
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};
// api to set user password and confirm password

export const getUserConfirm = async (body) => {
  const param = new URLSearchParams(location.search);
  const inviteToken = param.get("inviteToken");

  try {
    const data = await NetworkApi.post(paths.confirmUser(inviteToken), body);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};
