import NetworkApi from "./networkApi";
import { paths } from "./path";

// save the report in database
export const saveReport = async (body) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const data = await NetworkApi.post(paths.saveReportURL, body, headers).then(
      (res) => {
        localStorage.setItem("reportID", res.data.id);
        return res.message;
      }
    );
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

export const getMyReports = async () => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `${token}`,
  };
  try {
    const { data } = await NetworkApi.get(paths.myReportsURL, headers);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};
export const getViewReport = async (id) => {
  let token = localStorage.getItem("token");
  const headers = {
    Authorization: `${token}`,
  };
  try {
    const { data } = await NetworkApi.get(paths.viewReportURL(id), headers);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

//api for delete report

export const getDeleteReport = async (id) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const data = await NetworkApi.delete(paths.deleteReportUrl(id), headers);
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

export const getUpdateReport = async (data) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  const { name, schedule_automated, frequency, days, time, id } = data;
  const body = {
    name,
    schedule_automated,
    frequency,
    days,
    time,
  };
  try {
    const data = await NetworkApi.patch(
      paths.updateReportURL(id),
      body,
      headers
    );
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

export const getShareReport = async (body) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `${localStorage.getItem("token")}`,
  };
  let id = localStorage.getItem("reportID");
  try {
    const data = await NetworkApi.post(
      paths.shareReportURL(id),
      body,
      headers
    ).then((res) => {
      return res.message;
    });
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

export const getReportDetails = async (body) => {
  let token = localStorage.getItem("token");
  const headers = {
    Authorization: `${token}`,
  };
  try {
    const { data } = await NetworkApi.post(
      paths.checkReportDetailsUrl,
      body,
      headers
    ).then((res) => {
      localStorage.setItem("reportID", res.data.id);
      return res;
    });
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

//Starting for shared with me

export const getSharedWithMeReports = async () => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `${token}`,
  };
  try {
    const { data } = await NetworkApi.get(
      paths.sharedWithMeReportsURL,
      headers
    );
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

export const getSharedByMeViewReport = async (id) => {
  let token = localStorage.getItem("token");
  const headers = {
    Authorization: `${token}`,
  };
  try {
    const { data } = await NetworkApi.get(
      paths.sharedByMeViewReportURL(id),
      headers
    );
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

export const getSharedUserList = async (id) => {
  let token = localStorage.getItem("token");
  localStorage.setItem("reportID", id);
  const headers = {
    Authorization: `${token}`,
  };
  try {
    const { data } = await NetworkApi.post(
      paths.sharedUserListURL(id),
      "",
      headers
    );
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};

export const getremoveSharedUser = async (id) => {
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  try {
    const { data } = await NetworkApi.delete(
      paths.removeSharedUsertUrl(id),
      headers
    );
    return data;
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
};
