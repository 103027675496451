import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationFR from "./assets/i18n/locales/fr/translation.json";
import translationEN from "./assets/i18n/locales/en/translation.json";

const languages = ["en", "fr"];
const resources = {
  en: { translation: translationEN },
  fr: { translation: translationFR },
};
let lang=localStorage.getItem("language");
i18n.use(initReactI18next).init({
  fallbackLng: lang ? lang :"en",
  resources,
  whitelist: languages,
  debug: true,
  // keySeparator: false,  // by stoping this we will access the nested obj in language.json file
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
