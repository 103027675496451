const URL = import.meta.env.VITE_REACT_APP_API_BASE_URL;
import moment from "moment";
let LsEntity =
  localStorage.getItem("LsEntitySnapshot") != "undefined" &&
  JSON.parse(localStorage.getItem("LsEntitySnapshot"));
let LsDepartment =
  localStorage.getItem("LsDepartmentSnapshot") != "undefined" &&
  JSON.parse(localStorage.getItem("LsDepartmentSnapshot"));
export const paths = {
  LoginUrl: `${URL}api/auth/login`,
  changePasswordUrl: `${URL}api/auth/change/password`,
  forgotPasswordUrl: `${URL}api/auth/forgot`,
  inviteUserUrl: `${URL}api/auth/invite`,
  confirmUser: function (inviteToken) {
    return `${URL}api/auth/accept/invite?inviteToken=${inviteToken}`;
  },
  listingUrlForScope: `${URL}api/dataexplorer/filter`,
  pendingInvitesUrl: `${URL}api/users/invited/all`,
  confirmInvitesUrl: `${URL}api/users/confirmed/all`,
  deleteUserUrl: function (id) {
    return `${URL}api/admin/deleteEmployee/${id}`;
  },
  resendInviteUrl: function (id) {
    return `${URL}api/auth/resend/invite/${id}`;
  },
  updateUserUrl: function (id) {
    return `${URL}api/admin/updateEmployee/${id}`;
  },
  currentUser: `${URL}api/auth/profile`,
  getAllUsersUrl: `${URL}api/admin/allEmployees`,
  sanpshotURL: function (data) {
    let entity = data.entity
      .map((name, i) => {
        return `entity_name[${i}]=${name}&`;
      })
      .join("");

    let department = data.department
      .map((name, i) => {
        name = name.replace(/&/g, "%26");
        return `department_name[${i}]=${name}&`;
      })
      .join("");
    let newDate = data.newDate
      ? data.newDate
      : `${new Date().getFullYear()}-01-01`;
    let currentDate = data.currentDate
      ? data.currentDate
      : moment().format("YYYY-MM-DD");
    return `${URL}api/dashboard?${entity}${department}from=${newDate}&to=${currentDate}`;
  },
  initialSanpshotURL: function () {
    let entity = LsEntity?.map((name, i) => {
      return `entity_name[${i}]=${name}&`;
    }).join("");

    let department = LsDepartment?.map((name, i) => {
      name = name.replace(/&/g, "%26");
      return `department_name[${i}]=${name}&`;
    }).join("");

    return `${URL}api/dashboard?${entity}${department}from=${new Date().getFullYear()}-01-01&to=${moment().format(
      "YYYY-MM-DD"
    )}`;
  },
  //  `${URL}api/dashboard?entity_name[0]=demo&entity_name[1]=demo2&department_name[0]=
  // Sales&department_name[1]=Marketing%20%26%20Product&department_name[2]=Customer%20service&department_name[3]
  // =HR%20department&department_name[4]=IT&department_name[5]=Finances&from=${moment().format(
  //   "YYYY-MM-DD"
  // )}&to=${moment().format("YYYY-MM-DD")}`,
  filterListingURL: `${URL}api/dashboard/filter`,
  trendURL: function (data) {
    let entity = data.entity
      .map((name, i) => {
        return `entity_name[${i}]=${name}&`;
      })
      .join("");

    let department = data.department
      .map((name, i) => {
        name = name.replace(/&/g, "%26");
        return `department_name[${i}]=${name}&`;
      })
      .join("");
    return `${URL}api/dashboard/trends?${entity}${department}&from=${data.newDate}&to=${data.currentDate}`;
  },
  initialTrendURL: `${URL}api/dashboard/trends?entity_name[0]=demo&entity_name[1]=demo2&department_name[0]=Sales&department_name[1]=Marketing%20&%20Product&department_name[2]=Customer%20service&department_name[3]=HR%20department&department_name[4]=IT&department_name[5]=Finances&from=${new Date().getFullYear()}/01/01&to=${moment().format(
    "YYYY-MM-DD"
  )}`,
  filterListingUrlForDataExplorer: `${URL}api/dataexplorer/filter`,
  //Api for DataExplorer
  dataExpolorerURL: function (data) {
    let newDate = data.newDate;
    let currentDate = data.currentDate;
    let Metrics = data.Metrics;
    let X_Axis = data.X_Axis;
    let Groupby = data.groupby;
    let entity = data?.entity
      .map((name, i) => {
        return `entity_name[${i}]=${name}&`;
      })
      .join("");
    let department = data?.department
      .map((name, i) => {
        return `department_name[${i}]=${name}&`;
      })
      .join("");
    let sort = data?.sort;
    return `${URL}api/dataexplorer/?metric=${Metrics}&x_axis=${X_Axis}&groupby=${Groupby}&from=${newDate}&to=${currentDate}&sort=${sort}&${entity}${department}`;
  },
  dataExplorerPieChartURL: function (data) {
    let newDate = data.newDate;
    let currentDate = data.currentDate;
    let Metrics = data.Metrics;
    let Groupby = data.groupby;
    let entity = data?.entity
      .map((name, i) => {
        return `entity_name[${i}]=${name}&`;
      })
      .join("");
    let department = data?.department
      .map((name, i) => {
        return `department_name[${i}]=${name}&`;
      })
      .join("");
    let sort = data?.sort;
    ("metric=Headcount%2BTotal&x_axis=Days&groupby=Department&from=2022-04-24&to=2023-05-24&sort=undefined&entity_name[0]=demo&entity_name[1]=demo2&department_name[0]=Sales&department_name[1]=Marketing%20&%20Product&department_name[2]=Customer%20service&department_name[3]=HR%20department&department_name[4]=IT&department_name[5]=Finances&pieChart=true");
    return `${URL}api/dataexplorer/?metric=${Metrics}&x_axis=Days&groupby=${Groupby}&from=${newDate}&to=${currentDate}&sort=${sort}&${entity}${department}&pieChart=true`;
  },
  basicAdvAnalyticsURL: function (data) {
    let newDate = data.newDate;
    let currentDate = data.currentDate;
    let entity = data?.entity
      .map((name, i) => {
        return `entity_name[${i}]=${name}&`;
      })
      .join("");
    return `${URL}api/dashboard/employees_data?&${entity}&from=${newDate}&to=${currentDate}`;
  },
  goFurtherAdvAnalyticsURL: function (data) {
    let newDate = data.newDate;
    let currentDate = data.currentDate;
    let entity = data?.entity
      .map((name, i) => {
        return `entity_name[${i}]=${name}&`;
      })
      .join("");
    return `${URL}api/gofurther?&${entity}&from=${newDate}&to=${currentDate}`;
  },
  SummaryAdvAnalyticsURL: function (data) {
    let newDate = data?.newDate;
    let currentDate = data?.currentDate;
    let entity = data?.entity
      .map((name, i) => {
        return `entity_name[${i}]=${name}&`;
      })
      .join("");
      let lang=data.lang
    return `${URL}api/gofurther/insightSummary?&${entity}&from=${newDate}&to=${currentDate}&lang=${lang}`;
  },
  
  saveReportURL: `${URL}api/reports/create_report`,
  myReportsURL: `${URL}api/reports/myReports`,
  viewReportURL: function (viewId) {
    if (viewId) {
      return `${URL}api/reports/myReports/${viewId}`;
    }
  },
  sharedByMeViewReportURL: function (viewId) {
    if (viewId) {
      return `${URL}api/reports/sharedByMeById/${viewId}`;
    }
  },
  sharedUserListURL: function (viewId) {
    return `${URL}api/reports/getSharedReports/${viewId}`;
  },
  removeSharedUsertUrl: function (id) {
    return `${URL}api/reports/removeSharedUser/${id}`;
  },
  deleteReportUrl: function (id) {
    return `${URL}api/reports/deleteReports/${id}`;
  },
  updateReportURL: function (id) {
    return `${URL}api/reports/updateReports/${id}`;
  },
  shareReportURL: function (id) {
    return `${URL}api/reports/sharedReports/${id}`;
  },
  checkReportDetailsUrl: `${URL}api/reports/reportDetails`,
  sharedWithMeReportsURL: `${URL}api/reports/getAll/sharedByMe`,
};
