import {
  getAllUsers,
  getConfirmInvites,
  getDeleteUser,
  getPendingInvites,
  getResendInvite,
  getUpdateUser,
  getUpdateUserType,
  getUserInvite,
  getUserProfile,getScopeListing
} from "../api/admin";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useUserInvite = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ["user-invite"],
    (userInfo) => getUserInvite(userInfo),
    {
      onSuccess: () => {
        // Invalidate the user query so current user is requested again
        queryClient.invalidateQueries(["all-PendingInvites"]);
        toast.success("Invite send successfully.", {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(error.response.data.message, {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useDeleteMember = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ["Delete-Member"],
    (userInfo) => getDeleteUser(userInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["all-confirmInvites"]);
        queryClient.invalidateQueries(["all-PendingInvites"]);
        console.log("success");
        // Show an success toast
        toast.success("Member deleted successfully.", {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(error.response.data.msg, {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("error", error);
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useResendInvite = () => {
  const { mutate, isLoading, error } = useMutation(
    ["Resend-Invite"],
    (userInfo) => getResendInvite(userInfo),
    {
      onSuccess: () => {
        toast.success("Resend invite successfully.", {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("success");
      },
      onError: (error) => {
        // Show an error toast
        toast.error(error.response.data.msg, {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("error", error);
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useUpdateUserTypeDetails = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ["update-userTypeDetails"],
    (userInfo) => getUpdateUserType(userInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["all-confirmInvites"]);

        console.log("success");
      },
      onError: (error) => {
        console.log("error", error);
      },
    }
  );

  return { mutate, isLoading, error };
};

export const usePendingInvite = () => {
  const { data, isLoading, error, refetch } = useQuery(
    ["all-PendingInvites"],
    getPendingInvites,
    {
      enabled: true,
    }
  );
  return { data, isLoading, error, refetch };
};

export const useScopeListing = () => {
  const { data, isLoading, error, refetch } = useQuery(
    ["all-ScopesList"],
    getScopeListing,
    {
      enabled: true,
    }
  );
  return { data, isLoading, error, refetch };
};

export const useUpdateUserDetails = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ["update-userDetails"],
    (userInfo) => getUpdateUser(userInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["all-confirmInvites"]);
        queryClient.invalidateQueries(["all-PendingInvites"]);
        queryClient.invalidateQueries(["Current-User"]);
        toast.success("Update user successfully.", {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // console.log("success");
      },
      onError: (error) => {
        // Show an error toast
        toast.error(error.response.data.message, {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("error", error);
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useConfirmInvites = () => {
  const { data, isLoading, error, refetch } = useQuery(
    ["all-confirmInvites"],
    getConfirmInvites,
    {
      enabled: true,
    }
  );
  return { data, isLoading, error, refetch };
};

//get all users
export const useGetAllUsers = () => {
  const { data, isLoading, error, refetch } = useQuery(
    ["all-users"],
    getAllUsers,
    {
      enabled: false,
    }
  );

  return { data, isLoading, error, refetch };
};

export const useGetUserProfile = () => {
  const { data, isLoading, error, refetch } = useQuery(
    ["all-Profile"],
    getUserProfile,
    {
      enabled: true,
    }
  );

  return { data, isLoading, error, refetch };
};
