// Description: This component is used to show a loading animation when the user is waiting for the data to be fetched from the server.
import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import "./Loader.css";

const Loader = () => {
  return (
    <div>
      <br />
      <br />
      <BeatLoader
        color="#acceef"
        loading={true}
        size={20}
        cssOverride={{
          paddingTop: "50px",
          display: "flex",
          justifyContent: "center", 
          alignItems: "center",
        }}
      />
    </div>
  );
};

export default Loader;
