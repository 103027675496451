import React, { useState, Suspense } from "react";
import "./index.css";
import Sidebar from "../sidebar/Index";
import { memo } from "react";
const Layout = ({ children }) => {
  const [open, setOpen] = useState(true);
  const toggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div className="flex relative">
        <aside >
          <Sidebar
            toggleSidebar={toggleSidebar}
            open={open}
            setOpen={setOpen}
          />
        </aside>

        {/* Sidebar close button*/}


        {/* .Sidebar close end */}
        <main
          className={`flex bg-[#E9EEF5] ${open ? "w-[100%] pl-[300px]" : "w-[100%] ml-[71px] "
            }  relative  h-full	`}
        >

          <div className="w-full custom">
            <Suspense fallback={""}>{children}</Suspense>
          </div>
        </main>
      </div>
    </div>
  );
};
export default memo(Layout);
