import { useEffect, useState } from "react";
import {
  useDataForDataExplorer,
  useDataForDataExplorerPieChart,
  useFliterListingForDataExplorer,
} from "../../hooks/DataExplorer";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  useCheckReportDetails,
  useSaveReport,
  useShareReport,
} from "../../hooks/Reports";

// import {makeStyles} from "@emotion/styled";

function useDataExplorer() {
  const { t, i18n } = useTranslation();

  //Starting for reports
  const { mutate: saveReport } = useSaveReport();
  const { mutate: checkReportDetails, error } = useCheckReportDetails();
  const { data, refetch: refetchFilterList } =
    useFliterListingForDataExplorer();
  const {
    mutate,
    data: chartData,
    isLoading,
    status,
  } = useDataForDataExplorer();
  const { mutate: pieChartMutate, data: pieData } =
    useDataForDataExplorerPieChart();
  // hooks for filters
  const [open, setOpen] = useState(false);
  const [otherFilterOpen, setOtherFilterOpen] = useState(false);
  const [entity, setEntity] = useState([]);
  const [isSelectAllEntity, setIsSelectAllEntity] = useState(false);
  const [department, setDepartment] = useState([]);
  const [isSelectAllDepartment, setIsSelectAllDepartment] = useState(false);
  const [isMetricsOpen, setIsMetricsOpen] = useState(false);
  const [isX_AxisOpen, setIsX_AxisOpen] = useState(false);
  const [isGroupByOpen, setIsGroupByOpen] = useState(false);
  const [cDate, setCdate] = useState();
  const [nDate, setNdate] = useState();
  const [Metrics, setMetrics] = useState();
  const [X_Axis, setX_Axis] = useState("");
  const [groupby, setgroupby] = useState("");
  const [valueFormat, setValueFormat] = useState(true);
  const [timePeriodTitle, setTimePeriodTitle] = useState(
    t("Data_Explorer.Select_values")
  );
  const [metricTitle, setMetricTitle] = useState(
    t("Data_Explorer.Select_values")
  );
  const [X_AxisTitle, setX_AxisTitle] = useState(
    t("Data_Explorer.Select_values")
  );
  const [groupByTitle, setGroupByTitle] = useState(
    t("Data_Explorer.Select_values")
  );
  const [dateModel, setDateModel] = useState(false);
  const [customDate, setCustomDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [sort, setSort] = useState("");
  const [isSortOpen, setIsSortOpen] = useState(false);
  // functons for the filter dropdowns
  const departmentList = data?.Other?.DepartmentList;
  const entityList = data?.Other?.EntityList;
  const [chartTitle, setChartTitle] = useState("Headcount (End Period)");
  const [chartToShow, setChartToShow] = useState("");
  const [checkEntityAndDepartment, setCheckEntityAndDepartment] =
    useState(true);
  const [pieChartData, setPieChartData] = useState({ names: [], values: [] });
  const [barChartData, setBarChartData] = useState({
    CategoryName: [],
    DepartmentName: [],
    SeriesData: [],
  }); // his hook is used for both line and bar
  const [showValueFormatter, setShowValueFormatter] = useState(false);
  const [Orientation, setOrientation] = useState(false);
  const [attritionMetrics, setAttritionMatrics] = useState(false);
  const [metricTitleForReport, setMetricTitleForReport] = useState("");
  const handleDepartmentChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setDepartment([...department, value]);
    } else {
      let res = department.filter((e) => e !== value);
      setDepartment(res);
    }
  };
  const handleEntityChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setEntity([...entity, value]);
    } else {
      let res = entity.filter((e) => e !== value);
      setEntity(res);
    }
  };
  const selectAllEntity = (e) => {
    const { checked } = e.target;
    setIsSelectAllEntity(!isSelectAllEntity);
    if (checked) {
      setEntity(entityList?.map((item) => item.entity_name));
    } else {
      setEntity([]);
    }
  };
  // useEffect for check calling the chart data
  useEffect(() => {
    if (chartToShow === "Pie") {
      if (
        metricTitle === "Select values" ||
        timePeriodTitle === "Select values" ||
        (entity.length === 0 && department.length === 0)
      ) {
        // this is for if user is remove some filter which are mandatory then chart will be removed from the screen.
        setPieChartData({
          names: [],
          values: [],
        });
      } else if (
        nDate &&
        cDate &&
        entity.length !== 0 &&
        department.length !== 0 &&
        Metrics
      ) {
        // mutate({
        //   newDate: nDate,
        //   currentDate: cDate,
        //   entity,
        //   department,
        //   Metrics,
        //   X_Axis: "Days",
        //   groupby: groupby ? groupby : "",
        //   sort,
        // });
        pieChartMutate({
          newDate: nDate,
          currentDate: cDate,
          entity,
          department,
          Metrics,
          groupby: groupby ? groupby : "",
        });
      }
      checkReportDetails({
        X_Axis: null,
        chartType: "pie",
        metric: metricTitleForReport,
        groupby,
        currentDate: cDate,
        newDate: nDate,
        entity,
        department,
        valueFormat,
        orientation: Orientation,
        totalMetric: Metrics,
      });
    } else if (chartToShow === "bar" || chartToShow === "line") {
      if (
        metricTitle === "Select values" ||
        timePeriodTitle === "Select values" ||
        X_AxisTitle === "Select values" ||
        (entity.length === 0 && department.length === 0)
      ) {
        setBarChartData({
          CategoryName: [],
          DepartmentName: [],
          SeriesData: [],
        });
      } else if (
        nDate &&
        cDate &&
        entity.length !== 0 &&
        department.length !== 0 &&
        Metrics &&
        X_Axis
      ) {
        mutate({
          newDate: nDate,
          currentDate: cDate,
          entity,
          department,
          Metrics,
          X_Axis,
          groupby: groupby ? groupby : "",
          sort,
        });
        checkReportDetails({
          X_Axis: X_Axis,
          chartType: chartToShow,
          metric: metricTitleForReport,
          groupby,
          currentDate: cDate,
          newDate: nDate,
          entity,
          department,
          valueFormat,
          orientation: Orientation,
          totalMetric: Metrics,
        });
      }
    }
    if (X_Axis === "Department" || X_Axis === "") {
      refetchFilterList();
      if (chartToShow === "bar" || chartToShow === "line") {
        if (X_Axis === "Department" && groupby === "Department") {
          if (
            nDate &&
            cDate &&
            entity.length !== 0 &&
            department.length !== 0 &&
            Metrics &&
            X_Axis
          ) {
            mutate({
              newDate: nDate,
              currentDate: cDate,
              entity,
              department,
              Metrics,
              X_Axis,
              groupby: "",
              sort,
            });
            setgroupby("");
            setGroupByTitle("Select values");
          }
        }
      }
    }
  }, [
    nDate,
    cDate,
    Metrics,
    X_Axis,
    X_AxisTitle === "Select values",
    groupby,
    otherFilterOpen === false,
    chartToShow,
    X_AxisTitle,
    metricTitle,
    groupByTitle,
    timePeriodTitle,
    isSelectAllDepartment,
    isSelectAllEntity,
  ]);

  const selectAllDepartment = (e) => {
    const { checked } = e.target;
    setIsSelectAllDepartment(!isSelectAllDepartment);
    if (checked) {
      setDepartment(departmentList?.map((item) => item.department_name));
    } else {
      setDepartment([]);
    }
  };

  const onTimePeriodSubmit = (val) => {
    let d = moment();
    let cd = moment();
    let newDate;
    let currentDate;
    if (val === 1) {
      newDate = d.format("YYYY-MM-DD");
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);

      setTimePeriodTitle(t("snapshot.filter.Today"));
    } else if (val === 2) {
      newDate = d.subtract(30, "days").format("YYYY-MM-DD");
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);

      setTimePeriodTitle(t("snapshot.filter.Last_30_days"));
    } else if (val === 3) {
      newDate = d.subtract(3, "months").format("YYYY-MM-DD");
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);

      setTimePeriodTitle(t("snapshot.filter.Last_3_months"));
    } else if (val === 4) {
      newDate = d.subtract(6, "months").format("YYYY-MM-DD");
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);

      setTimePeriodTitle(t("snapshot.filter.Last_6_months"));
    } else if (val === 5) {
      newDate = d.subtract(1, "years").format("YYYY-MM-DD");
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);
      setTimePeriodTitle(t("snapshot.filter.Last_12_months"));
    } else if (val === 6) {
      setDateModel(true);
      // setTimePeriodTitle("Custom");
    } else if (val === 7) {
      newDate = `${new Date().getFullYear()}-01-01`;
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);

      setTimePeriodTitle(t("snapshot.filter.yearToDate"));
    }
    setOpen((prev) => !prev);
  };

  const onOtherFilterSubmite = () => {
    if (entity.length !== 0 && department.length !== 0) {
      setCheckEntityAndDepartment(true);
    } else {
      setCheckEntityAndDepartment(false);
    }
    setOtherFilterOpen(false);
  };

  // translation for fliter listings
  let metricsObj = {
    Headcount: t("filter.Headcount"),
    Recruitment: t("filter.Recruitment"),
    Attrition: t("filter.Attrition"),
    Compensation: t("filter.Compensation"),
    Absenteism: t("filter.Absenteism"),
  };
  let subMetricsObj = {
    "End Period": t("filter.EndPeriod"),
    Total: t("filter.Total"),
    "Begin Period": t("filter.BeginPeriod"),
    Arrivals: t("filter.Arrivals"),
    "Open Jobs": t("filter.OpenJobs"),
    Departures: t("filter.Departures"),
    "Turnover Rate": t("filter.TurnoverRate"),
    "Attrition Rate": t("filter.AttritionRate"),
    "Total Compensation": t("filter.TotalCompensation"),
    "Average Compensation": t("filter.AverageCompensation"),
    "Median Compensation": t("filter.MedianCompensation"),
    "Days Off": t("filter.DaysOff"),
    "Absenteism Rate": t("filter.AbsenteismRate"),
    Attrition: t("filter.Attrition"),
  };
  let X_AxisObj = {
    Days: t("filter.Days"),
    Weeks: t("filter.Weeks"),
    Month: t("filter.Month"),
    Quarter: t("filter.Quarter"),
    Semester: t("filter.Semester"),
    Year: t("filter.Year"),
    Department: t("filter.Department"),
  };
  let groupByObj = {
    Department: t("filter.Department"),
    "Contract Type": t("filter.ContractType"),
    Gender: t("filter.Gender"),
  };
  const [compensationFormater, setCompensationFormater] = useState(false); //currency format for compensation
  const onMetricsSubmit = (val, subTitle, title) => {
    if (
      val === "Compensation%2BTotal Compensation" ||
      val === "Compensation%2BAverage Compensation" ||
      val === "Compensation%2BMedian Compensation"
    ) {
      setCompensationFormater(true);
    } else {
      setCompensationFormater(false);
    }
    if (
      val === "Headcount%2BEnd Period" ||
      val === "Headcount%2BTotal" ||
      val === "Headcount%2BBegin Period" ||
      val === "Recruitment%2BArrivals" ||
      val === "Recruitment%2BOpen Jobs" ||
      val === "Attrition%2BDepartures" ||
      val === "Absenteism%2BDays Off" ||
      val === "Compensation%2BTotal Compensation"
    ) {
      setShowValueFormatter(true);
    } else {
      setShowValueFormatter(false);
    }
    setMetrics(val);
    if (val === "Attrition%2BTurnover Rate" ||val === "Attrition%2BAttrition Rate") {
      setAttritionMatrics(true);
      setValueFormat(false)
    } else {
      setAttritionMatrics(false);
      setValueFormat(true)
    }

    if (subTitle === "Total") {
      setChartTitle(`Headcount`);
      setMetricTitle(t("filter.Headcount"));
      setMetricTitleForReport(`${title}`);
    } else if (subTitle === "Days Off") {
      setChartTitle(`Number of days off`);
      setMetricTitle(t("filter.DaysOff"));
      setMetricTitleForReport(`${title}`);
    } else if (
      title === "Recruitment" ||
      title === "Attrition" ||
      title === "Compensation" ||
      title === "Absenteism"
    ) {
      setChartTitle(`${subTitle}`);
      setMetricTitle(subMetricsObj[subTitle]);
      setMetricTitleForReport(`${title}`);
    } else {
      setChartTitle(`${subTitle} ${title}`);
      setMetricTitle(`${subMetricsObj[subTitle]} ${metricsObj[title]}`);
      setMetricTitleForReport(`${title}`);
    }
    setIsMetricsOpen((prev) => !prev);
  };
  const onX_AxisSubmit = (val) => {
    setX_Axis(val);
    setX_AxisTitle(X_AxisObj[val]);

    setIsX_AxisOpen((prev) => !prev);
  };
  const onGroupbySubmit = (val) => {
    setgroupby(val);
    setGroupByTitle(groupByObj[val]);
    setIsGroupByOpen((prev) => !prev);
  };

  const onSortingsChanged = (val) => {
    mutate({
      newDate: nDate,
      currentDate: cDate,
      entity,
      department,
      Metrics,
      X_Axis,
      groupby: groupby ? groupby : "",
      sort: val,
    });
    setIsSortOpen(false);
  };

  const handleClickAway = (val) => {
    if (val === "Metrics") {
      setIsMetricsOpen(false);
    } else if (val === "X_Axis") {
      setIsX_AxisOpen(false);
    } else if (val === "GroupBy") {
      setIsGroupByOpen(false);
    } else if (val === "timePeriod") {
      setOpen(false);
    } else if (val === "otherFilter") {
      setOtherFilterOpen(false);
    } else if (val === "sortBy") {
      setIsSortOpen(false);
    }
  };
  const handleCustomDateSubmite = () => {
    let endDate = customDate[0].endDate;
    let endDateDay = endDate.getDate();
    let endDateMonth = endDate.getMonth() + 1;
    if (endDateDay < 10) {
      endDateDay = "0" + endDateDay;
    }
    if (endDateMonth < 10) {
      endDateMonth = "0" + endDateMonth;
    }

    let startDate = customDate[0].startDate;
    let startDateDay = startDate.getDate();
    let startDateMonth = startDate.getMonth() + 1;

    if (startDateDay < 10) {
      startDateDay = "0" + startDateDay;
    }
    if (startDateMonth < 10) {
      startDateMonth = "0" + startDateMonth;
    }

    let newDate = `${startDate.getFullYear()}-${startDateMonth}-${startDateDay}`;
    let currentDate = `${endDate.getFullYear()}-${endDateMonth}-${endDateDay}`;
    setNdate(newDate);
    setCdate(currentDate);

    setDateModel(false);
    setTimePeriodTitle(`${newDate} to ${currentDate}`);
  };
  useEffect(() => {
    if (entity?.length === entityList?.length) {
      setIsSelectAllEntity(true);
    } else {
      setIsSelectAllEntity(false);
    }
    if (department?.length === departmentList?.length) {
      setIsSelectAllDepartment(true);
    } else {
      setIsSelectAllDepartment(false);
    }
  }, [entity, department]);

  // data for charts

  // let filterData = Object.keys(chartData ? chartData : {});
  //data based on the metrics

  //useEffect for chart data when values are provided by user.
  useEffect(() => {
    // for pie chart
    //total headcount data
    if (!groupby || groupby === "") {
      if (pieData?.length > 0) {
        let names = pieData?.map((dep, i) => {
          return " " + i;
        });
        let values =
          pieData?.length > 0
            ? [Number(Number(Object.values(pieData[0])[0]).toFixed())]
            : [];
        if (Metrics === "Compensation%2BTotal Compensation") {
          setPieChartData({ names, values, isCompensationTotal: true });
        } else {
          setPieChartData({ names, values, isCompensationTotal: false });
        }
      } else {
        setPieChartData({ names: [], values: [], isCompensationTotal: false });
      }

      if (chartData?.length > 0) {
        let CategoryName = chartData?.map((item) => item.x_axis);
        let DepartmentName = chartData?.map((dep, i) => {
          return " " + i;
        });
        let SeriesData = chartData?.map((item) => {
          let data = item?.data[0]?.value;
          return Number(data)?.toFixed(2);
        });
        let chartDataforBar = CategoryName?.map((data, i) => {
          let mainData = {};
          mainData["year"] = data;
          mainData["value"] = SeriesData[i];
          return mainData;
        });

        let total = SeriesData?.reduce((acc, curr) => {
          return (acc += Number(curr));
        }, 0);
        const chartDataforBarInPercentage = CategoryName?.map((data, i) => {
          let mainData = {};
          mainData["year"] = data;
          mainData["value"] = (Number(SeriesData[i]) / total) * 100;
          return mainData;
        });
        setBarChartData({
          CategoryName,
          DepartmentName,
          SeriesData,
          chartDataforBar,
          chartDataforBarInPercentage,
          isGroupBy: true,
        });
      } else {
        setBarChartData({
          CategoryName: [],
          DepartmentName: [],
          SeriesData: [],
        });
      }
      // setBarChartData({ CategoryName: [], DepartmentName: [], SeriesData: [] });
    } else if (groupby) {
      // starting for pie chart
      if (pieData?.length > 0) {
        let names = pieData?.map((dep) => {
            return dep.groupby;
        })
        let values = pieData?.map((val,i) => {
            if(Number(Object.values(val)[1])<1){
              return Number(Number(Object.values(val)[1]).toFixed(3));
            }
            return Number(Number(Object.values(val)[1]).toFixed(0));
        });
        if (Metrics === "Compensation%2BTotal Compensation") {
          setPieChartData({ names, values, isCompensationTotal: true });
        } else {
          setPieChartData({ names, values, isCompensationTotal: false });
        }
      } else {
        setPieChartData({ names: [], values: [], isCompensationTotal: false });
      }
      // starting for bar chart and line chart
      if (chartData?.length !== 0) {
        let CategoryName = chartData?.map((item) => item.x_axis);
        // common department names for stacking bars
        let DepartmentName = chartData
          ? chartData[0]?.data?.map((dep) => {
              return dep.key;
            })
          : [];
        // data for each bar
        //begin headcount data for each column
        let SeriesData = DepartmentName?.map((item) => {
          let data = [];
          for (let i = 0; i < chartData?.length; i++) {
            for (let j = 0; j < chartData[i]?.data?.length; j++) {
              if (item === chartData[i].data[j].key) {
                data.push(Number(chartData[i]?.data[j].value).toFixed(2));
              }
            }
          }
          return data;
        });
        let chartDataforBar = chartData?.map((data, i) => {
          let mainData = {};
          let year = data.x_axis;
          mainData["year"] = year;
          data?.data?.map((item) => {
            mainData[item.key] = item.value;
          });
          return mainData;
        });
        const chartDataforBarInPercentage = chartData?.map((data, i) => {
          let mainData = {};
          let year = data.x_axis;
          mainData["year"] = year;
          let total = data?.data?.reduce((acc, curr) => {
            return (acc += curr.value);
          }, 0);
          data?.data?.map((item) => {
            // mainData[item.key] = item.value;
            mainData[item.key] = (item.value / total) * 100;
          });
          return mainData;
        });
        setBarChartData({
          CategoryName,
          DepartmentName,
          SeriesData,
          chartDataforBar,
          chartDataforBarInPercentage,
          isGroupBy: false,
        });
      } else {
        setBarChartData({
          CategoryName: [],
          DepartmentName: [],
          SeriesData: [],
        });
      }
    }
  }, [chartData, pieData, groupby, Metrics, groupByTitle]);

  // starting for export ,save and share the chart

  const [withoutSaveOpen, setWithoutSaveOpen] = useState(false);
  const [reportShareOpen, setReportShareOpen] = useState(false);
  const [reportSaveOpen, setReportSaveOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState(
    `${chartTitle} ${groupby ? `by ${groupby}` : ""}`
  );
  const [reportTitleForView, setReportTitleForView] = useState("");
  const [scheduleCheck, setScheduleCheck] = useState(false);
  const [time, setTime] = useState("08:00");
  const [reportType, setReportType] = useState("weekly");
  const [days, setDays] = useState(
    reportType == "weekly"
      ? "Mon"
      : reportType === "monthly"
      ? "1st day"
      : reportType === "quarterly"
      ? "1st day"
      : null
  );
  const { mutate: shareReport } = useShareReport();
  useEffect(() => {
    setDays(
      reportType == "weekly"
        ? "Mon"
        : reportType === "monthly"
        ? "1st day"
        : reportType === "quarterly"
        ? "1st day"
        : null
    );
  }, [reportType]);

  useEffect(() => {
    if (chartToShow === "Pie") {
      setReportTitle(
        `${metricsObj[metricTitleForReport]} ${
          groupby
            ? `${i18n.language === "en" ? "by" : "par"} ${groupByObj[groupby]}`
            : ""
        }`
      );
      setReportTitleForView(`${chartTitle} ${groupby ? `by ${groupby}` : ""}`);
    } else {
      setReportTitle(
        X_Axis !== ""
          ? `${metricsObj[metricTitleForReport]} ${
              i18n.language === "en" ? "by" : "par"
            } ${X_AxisObj[X_Axis]} ${
              groupby
                ? `${i18n.language === "en" ? "and" : "et"} ${
                    groupByObj[groupby]
                  }`
                : ""
            }`
          : `${metricsObj[metricTitleForReport]} ${
              groupby
                ? `${i18n.language === "en" ? "by" : "par"} ${
                    groupByObj[groupby]
                  }`
                : ""
            }`
      );
      setReportTitleForView(
        X_Axis !== ""
          ? `${chartTitle} by ${X_Axis} ${groupby ? `and ${groupby}` : ""}`
          : `${chartTitle} ${groupby ? `by ${groupby}` : ""}`
      );
    }
  }, [pieData, chartData, chartToShow]);
  const onReportShare = () => {
    if (localStorage.getItem("reportID") !== "null") {
      setReportShareOpen(!reportShareOpen);
    } else {
      setWithoutSaveOpen(true);
    }
  };
  const onSaveReportSubmit = (title) => {
    if (chartToShow === "Pie") {
      let res = {
        name: title,
        reportData: pieData,
        frequency: scheduleCheck ? reportType : null,
        days: scheduleCheck ? days : null,
        time: scheduleCheck ? time : null,
        chartType: "pie",
        schedule_automated: scheduleCheck,
        metric: metricTitleForReport,
        entity,
        department,
        currentDate: cDate,
        newDate: nDate,
        groupby,
        valueFormat,
        reportTitle: reportTitleForView,
        totalMetric: Metrics,
      };
      saveReport(res);
    } else {
      let res = {
        name: title,
        reportData: chartData,
        frequency: scheduleCheck ? reportType : null,
        days: scheduleCheck ? days : null,
        time: scheduleCheck ? time : null,
        chartType: chartToShow,
        schedule_automated: scheduleCheck,
        metric: metricTitleForReport,
        entity,
        department,
        currentDate: cDate,
        newDate: nDate,
        groupby,
        valueFormat,
        orientation: Orientation,
        X_Axis,
        attritionMetrics,
        reportTitle: reportTitleForView,
        totalMetric: Metrics,
      };
      saveReport(res);
    }
    setReportSaveOpen(!reportSaveOpen);
  };
  return {
    t,
    i18n,
    isLoading,
    status,
    data,
    entity,
    department,
    open,
    setOpen,
    otherFilterOpen,
    setOtherFilterOpen,
    isSelectAllEntity,
    isSelectAllDepartment,
    handleDepartmentChange,
    handleEntityChange,
    selectAllEntity,
    selectAllDepartment,
    isMetricsOpen,
    setIsMetricsOpen,
    isX_AxisOpen,
    setIsX_AxisOpen,
    isGroupByOpen,
    setIsGroupByOpen,
    onTimePeriodSubmit,
    onOtherFilterSubmite,
    setMetrics,
    onMetricsSubmit,
    onGroupbySubmit,
    onX_AxisSubmit,
    mutate,
    handleClickAway,
    timePeriodTitle,
    setTimePeriodTitle,
    metricTitle,
    setMetricTitle,
    X_AxisTitle,
    setX_AxisTitle,
    groupByTitle,
    setGroupByTitle,
    dateModel,
    setDateModel,
    customDate,
    handleCustomDateSubmite,
    setCustomDate,
    pieChartData,
    chartData,
    barChartData,
    sort,
    setSort,
    isSortOpen,
    setIsSortOpen,
    handleClickAway,
    onSortingsChanged,
    chartTitle,
    groupby,
    Metrics,
    X_Axis,
    chartToShow,
    setChartToShow,
    checkEntityAndDepartment,
    showValueFormatter,
    setShowValueFormatter,
    setCheckEntityAndDepartment,
    setIsSelectAllEntity,
    setIsSelectAllDepartment,
    setEntity,
    setDepartment,
    setgroupby,
    setX_Axis,
    attritionMetrics,
    withoutSaveOpen,
    setWithoutSaveOpen,
    onReportShare,
    reportShareOpen,
    setReportShareOpen,
    setReportSaveOpen,
    reportSaveOpen,
    scheduleCheck,
    setScheduleCheck,
    onSaveReportSubmit,
    reportTitle,
    setReportTitle,
    time,
    setTime,
    days,
    setDays,
    reportType,
    Orientation,
    setOrientation,
    setReportType,
    valueFormat,
    setValueFormat,
    shareReport,
    reportTitleForView,
    metricsObj,
    subMetricsObj,
    X_AxisObj,
    groupByObj,
    metricTitleForReport,
    compensationFormater,
  };
}

export default useDataExplorer;
