import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getDeleteReport,
  getMyReports,
  getReportDetails,
  getShareReport,
  getSharedByMeViewReport,
  getSharedUserList,
  getSharedWithMeReports,
  getUpdateReport,
  getViewReport,
  getremoveSharedUser,
  saveReport,
} from "../api/reports";
import { toast } from "react-toastify";

export const useSaveReport = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ["saveReport"],
    (userInfo) => saveReport(userInfo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("myReports");
        queryClient.invalidateQueries("checkReports");

        // Show a success toast
        toast.success(data, {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(error, {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useMyReports = () => {
  const queryClient = useQueryClient();

  const { data, isLoading, error, refetch, isFetching } = useQuery(
    "myReports",
    getMyReports,
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 50000,
      retryOnMount: false,
      enabled: true,
      onError: (data) => {
        queryClient.setQueryData("myReports", (oldQueryData) => {
          if (data?.response.data.message === "No reports found") {
            return [];
          }
        });
        return data;
      },
    }
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useViewReport = (viewID) => {
  const { data, isLoading, error, refetch } = useQuery(
    ["viewReports", viewID],
    () => getViewReport(viewID),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 3000,
      retryOnMount: false,
      enabled: true,
    }
  );
  return { data, isLoading, error, refetch };
};
export const useDeleteReport = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ["deleteReport"],
    (userInfo) => getDeleteReport(userInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("myReports");
        // Show an success toast
        toast.success("Report deleted successfully.", {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(error.response.data.msg, {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // console.log("error", error);
      },
    }
  );

  return { mutate, isLoading, error };
};
export const useUpdateReport = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ["update-report"],
    (userInfo) => getUpdateReport(userInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["myReports"]);

        toast.success("Update report successfully.", {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // console.log("success");
      },
      onError: (error) => {
        // Show an error toast
        toast.error(error.response.data.message, {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // console.log("error", error);
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useShareReport = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ["share-report"],
    (userInfo) => getShareReport(userInfo),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["myReports"]);

        toast.success("Report Shared successfully.", {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // console.log("success");
      },
      onError: (error) => {
        // Show an error toast
        toast.error(error.response.data.message, {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useCheckReportDetails = () => {
  const { mutate, isLoading, error } = useMutation(
    ["checkReports"],
    (filters) => getReportDetails(filters)
  );

  return { mutate, isLoading, error };
};

// starting for shared with me

export const useSharedWithMeReports = () => {
  const queryClient = useQueryClient();

  const { data, isLoading, error, refetch, isFetching } = useQuery(
    "sharedWithMeReports",
    getSharedWithMeReports,
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 50000,
      retryOnMount: false,
      enabled: true,
      onError: (data) => {
        queryClient.setQueryData("sharedWithMeReports", (oldQueryData) => {
          // this condition for returing the empty array to show the fall back message on ui.
          if (data?.response.data.message === "Shared reports not found") {
            return [];
          }
        });

        return data;
      },
    }
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useSharedByMeViewReport = (viewID) => {
  const { data, isLoading, error, refetch } = useQuery(
    ["shareByMeViewReports", viewID],
    () => getSharedByMeViewReport(viewID),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 50000,
      retryOnMount: false,
      enabled: true,
    }
  );
  return { data, isLoading, error, refetch };
};

export const useSharedUsersList = () => {
  const { mutate, isLoading, error, data } = useMutation(
    "sharedUserList",
    (id) => getSharedUserList(id)
  );
  return { mutate, isLoading, error, data };
};

export const useRemoveSharedUser = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error, data } = useMutation(
    ["RemoveSharedUser"],
    (userInfo) => getremoveSharedUser(userInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sharedUserList");
        // Show an success toast

        toast.success("User access removed successfully.", {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(error.response.data.msg, {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // console.log("error", error);
      },
    }
  );

  return { mutate, isLoading, error };
};
