import React, { useEffect, useState } from "react";
import {
  useDeleteReport,
  useMyReports,
  useRemoveSharedUser,
  useShareReport,
  useSharedByMeViewReport,
  useSharedUsersList,
  useSharedWithMeReports,
  useUpdateReport,
  useViewReport,
} from "../../hooks/Reports";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function useReports() {
  const path = useLocation();
  const [viewData, setViewData] = useState();
  const location = path.pathname.substring(1, path.pathname.lastIndexOf("/"));
  const { t } = useTranslation();
  const { data, isLoading, isFetching, refetch } = useMyReports();
  const {
    data: sharedWithMeData,
    isLoading: shareWithMeLoading,
    isFetching: shareWithMeFetching,
    refetch: refetchShareWithMeData,
  } = useSharedWithMeReports();
  const { mutate: deleteReport } = useDeleteReport();
  const { mutate: UpdateReport } = useUpdateReport();
  const { viewID } = useParams();
  const { data: myReportViewData, isLoading: myReportDataLoading } =
    useViewReport(location === "myreports" && viewID);
  const { data: sharedByMeViewReportData, isLoading: shareWithMeDataLoading } =
    useSharedByMeViewReport(location === "sharedwithme" && viewID);
  const {
    mutate: getSharedUserList,
    data: sharedUserList,
    isLoading: sharedUserListLoading,
  } = useSharedUsersList();
  const {
    mutate: removedSharedUser,
    data: isRemovedUser,
    isLoading: removeUserLoading,
  } = useRemoveSharedUser();
  const { mutate: shareReport } = useShareReport();
  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   if (!isLoading) {
  //     setData(myReportData);
  //   }
  // }, [myReportData]);
  /////////////// Starting for my report page /////////////////
  const [ReportShareOpen, setReportShareOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [myReportsData, setMyReportsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [shareWithMeData, setShareWithMeData] = useState([]);
  const [updateReportOpen, setUpdateReportOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [reportTitle, setReportTitle] = useState(viewData?.name);
  const [scheduleCheck, setScheduleCheck] = useState(false);
  const [time, setTime] = useState("08:00");
  const [reportType, setReportType] = useState("weekly");
  const [days, setDays] = useState(
    reportType == "weekly"
      ? "Mon"
      : reportType === "monthly"
      ? "1st day"
      : reportType === "quarterly"
      ? "1st day"
      : null
  );
  const [reportTypeOpen, setReportTypeOpen] = useState(false);
  const [sharedUserId, setSharedUserId] = useState("");
  useEffect(() => {
    setDays(
      reportType == "weekly"
        ? "Mon"
        : reportType === "monthly"
        ? "1st day"
        : reportType === "quarterly"
        ? "1st day"
        : null
    );
  }, [reportType]);

  const onReportTypeSubmit = (val) => {
    setReportType(val);
    setReportTypeOpen(!reportTypeOpen);
  };

  useEffect(() => {
    if (!isLoading && data.length >= 1) {
      setMyReportsData(data);
      setFilterData(data);
    }
  }, [data]);
  useEffect(() => {
    if (!shareWithMeLoading && sharedWithMeData.length >= 1) {
      setShareWithMeData(sharedWithMeData);
      setFilterData(sharedWithMeData);
    }
  }, [sharedWithMeData]);
  const showUpdateModal = (data) => {
    setReportTitle(data.name);
    setUpdateId(data.id);
    if (viewData?.schedule_automated) {
      setReportType(viewData?.frequency);
      setTime(viewData?.time);
      setDays(viewData?.days);
    }
    setUpdateReportOpen(true);
  };
  const onUpdateReportSubmit = (title, e) => {
    e.preventDefault();
    UpdateReport({
      name: title,
      schedule_automated: scheduleCheck,
      frequency: scheduleCheck ? reportType : null,
      days: scheduleCheck ? days : null,
      time: scheduleCheck ? time : null,
      id: updateId,
    });

    setUpdateReportOpen(false);
  };
  const handleDeleteReportConfirmation = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };
  const onDeleteReportSubmit = () => {
    deleteReport(deleteId);
    setShowDeleteModal(false);
  };
  /////////////////////// starting for view Report page /////////////////////////

  const [barChartData, setBarChartData] = useState({
    CategoryName: [],
    DepartmentName: [],
    SeriesData: [],
  });
  const [pieChartData, setPieChartData] = useState({ names: [], values: [] });
  const [valueFormat, setValueFormat] = useState(false);
  const [groupby, setGroupBy] = useState("");
  const [newDate, setNewDate] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [entity, setEntity] = useState([]);
  const [department, setDepartment] = useState([]);
  const [showValueFormatter, setShowValueFormatter] = useState(true);
  const [showValueFormatterPie, setShowValueFormatterPie] = useState(true);
  const [Orientation, setOrientation] = useState(false);
  const [attritionMetrics, setAttritionMatrics] = useState(false);
  const [X_Axis, setX_Axis] = useState("");

  const onSharedUserClick = (id) => {
    setReportShareOpen(true);
    getSharedUserList(id);
    setSharedUserId(id);
  };

  useEffect(() => {
    if (!myReportDataLoading) {
      setViewData(myReportViewData);
      setEntity(myReportViewData?.entity);
      setDepartment(myReportViewData?.department);
      setCurrentDate(myReportViewData?.currentDate);
      setNewDate(myReportViewData?.newDate);
    }
  }, [myReportViewData]);
  useEffect(() => {
    if (!shareWithMeDataLoading) {
      setViewData(sharedByMeViewReportData?.create_report);
      setEntity(sharedByMeViewReportData?.create_report?.entity);
      setDepartment(sharedByMeViewReportData?.create_report?.department);
      setCurrentDate(sharedByMeViewReportData?.create_report?.currentDate);
      setNewDate(sharedByMeViewReportData?.create_report?.newDate);
    }
  }, [sharedByMeViewReportData]);
  const chartData = viewData?.reportData;
  useEffect(() => {
    // starting for pie chart
    setAttritionMatrics(viewData?.attritionMetrics);
    setOrientation(viewData?.orientation);
    setGroupBy(viewData?.groupby);
    setX_Axis(viewData?.X_Axis);
    if (!viewData?.groupby || viewData?.groupby === "") {
      setValueFormat(viewData?.valueFormat);
      if (viewData?.chartType === "pie") {
        let names = chartData?.map((dep, i) => {
          return " " + i;
        });
        let values =
          chartData?.length > 0
            ? [Number(Number(Object.values(chartData[0])[0]).toFixed())]
            : [];
        if (viewData?.metric === "Compensation%2BTotal Compensation") {
          setPieChartData({ names, values, isCompensationTotal: true });
          setShowValueFormatterPie(true);
        } else {
          setPieChartData({ names, values, isCompensationTotal: false });
        }
      } else {
        setPieChartData({ names: [], values: [], isCompensationTotal: false });
      }

      if (viewData?.chartType === "bar" || viewData?.chartType === "line") {
        let CategoryName = chartData?.map((item) => item.x_axis);
        let DepartmentName = chartData?.map((dep, i) => {
          return " " + i;
        });
        let SeriesData = chartData?.map((item) => {
          let data = item.data[0].value;
          return Number(data).toFixed(2);
        });
        let chartDataforBar = CategoryName?.map((data, i) => {
          let mainData = {};
          mainData["year"] = data;
          mainData["value"] = SeriesData[i];
          return mainData;
        });

        let total = SeriesData?.reduce((acc, curr) => {
          return (acc += Number(curr));
        }, 0);
        const chartDataforBarInPercentage = CategoryName?.map((data, i) => {
          let mainData = {};
          mainData["year"] = data;
          mainData["value"] = (Number(SeriesData[i]) / total) * 100;
          return mainData;
        });
        setBarChartData({
          CategoryName,
          DepartmentName,
          SeriesData,
          chartDataforBar,
          chartDataforBarInPercentage,
          isGroupBy: true,
        });
      }
      // setBarChartData({ CategoryName: [], DepartmentName: [], SeriesData: [] });
    } else if (viewData?.groupby) {
      if (viewData?.chartType === "pie") {
        setValueFormat(viewData?.valueFormat);
        let names = chartData?.map((dep) => {
          return dep.groupby;
        })
        let values = chartData?.map((val) => {
          if(Number(Object.values(val)[1])<1){
            return Number(Number(Object.values(val)[1]).toFixed(3));
            }
          return Number(Number(Object.values(val)[1]).toFixed());
        })
        if (viewData?.totalMetric === "Compensation%2BTotal Compensation") {
          setPieChartData({ names, values, isCompensationTotal: true });
          setShowValueFormatterPie(true);
        } else if (
          viewData?.totalMetric === "Compensation%2BAverage Compensation" ||
          viewData?.totalMetric === "Compensation%2BMedian Compensation"
        ) {
          setPieChartData({ names, values, isCompensationTotal: false });
          setShowValueFormatterPie(false);
        } else {
          setPieChartData({ names, values, isCompensationTotal: false });
        }
      } else {
        setPieChartData({ names: [], values: [], isCompensationTotal: false });
      }

      // starting for bar chart and line chart
      if (viewData?.chartType === "bar" || viewData?.chartType === "line") {
        setValueFormat(viewData.valueFormat);
        let CategoryName = chartData?.map((item) => item.x_axis);
        // common department names for stacking bars
        let DepartmentName = chartData
          ? chartData[0]?.data?.map((dep) => {
              return dep.key;
            })
          : [];
        // data for each bar
        //begin headcount data for each column
        let SeriesData = DepartmentName?.map((item) => {
          let data = [];
          for (let i = 0; i < chartData?.length; i++) {
            for (let j = 0; j < chartData[i]?.data?.length; j++) {
              if (item === chartData[i].data[j].key) {
                data.push(Number(chartData[i]?.data[j].value).toFixed(2));
              }
            }
          }
          return data;
        });
        let chartDataforBar = chartData?.map((data, i) => {
          let mainData = {};
          let year = data.x_axis;
          mainData["year"] = year;
          data?.data?.map((item) => {
            mainData[item.key] = item.value;
          });
          return mainData;
        });
        const chartDataforBarInPercentage = chartData?.map((data, i) => {
          let mainData = {};
          let year = data.x_axis;
          mainData["year"] = year;
          let total = data?.data?.reduce((acc, curr) => {
            return (acc += curr.value);
          }, 0);
          data?.data?.map((item) => {
            // mainData[item.key] = item.value;
            mainData[item.key] = (item.value / total) * 100;
          });
          return mainData;
        });
        setBarChartData({
          CategoryName,
          DepartmentName,
          SeriesData,
          chartDataforBar,
          chartDataforBarInPercentage,
          isGroupBy: false,
        });
      } else {
        setBarChartData({
          CategoryName: [],
          DepartmentName: [],
          SeriesData: [],
        });
      }
    }
  }, [chartData]);

  const handleSearchData = (e) => {
    let query = e.target.value;
    let updatedReports = [...filterData];
    updatedReports = updatedReports?.filter((item) => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setMyReportsData(updatedReports);
  };
  const handleSearchDataForShareWithMe = (e) => {
    let query = e.target.value;
    let updatedReports = [...filterData];
    updatedReports = updatedReports?.filter((item) => {
      return (
        item.create_report.name.toLowerCase().indexOf(query.toLowerCase()) !==
        -1
      );
    });
    setShareWithMeData(updatedReports);
  };
  //starting for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 10;
  const lastIndex = currentPage * reportsPerPage;
  const firstIndex = lastIndex - reportsPerPage;
  const nPages = Math.ceil(myReportsData?.length / reportsPerPage);
  const numbers = [...Array((nPages ? nPages : 1) + 1).keys()].slice(1);
  const reports = myReportsData?.slice(firstIndex, lastIndex);
  const npageForSharewithMe = Math.ceil(
    shareWithMeData?.length / reportsPerPage
  );
  const numbersForShareWithMe = [
    ...Array((npageForSharewithMe ? npageForSharewithMe : 0) + 1).keys(),
  ].slice(1);
  const sharedWithMeReports = shareWithMeData?.slice(firstIndex, lastIndex);
  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const changeCurrPage = (num) => {
    setCurrentPage(num);
  };

  return {
    t,
    location,
    sharedWithMeReports,
    numbersForShareWithMe,
    nextPage,
    currentPage,
    changeCurrPage,
    prePage,
    reports,
    numbers,
    data,
    isLoading,
    isFetching,
    useMyReports,
    refetch,
    ReportShareOpen,
    setReportShareOpen,
    myReportsData,
    handleDeleteReportConfirmation,
    showDeleteModal,
    setShowDeleteModal,
    onDeleteReportSubmit,
    viewData,
    barChartData,
    pieChartData,
    valueFormat,
    setValueFormat,
    showValueFormatter,
    setShowValueFormatter,
    showValueFormatterPie,
    Orientation,
    groupby,
    X_Axis,
    attritionMetrics,
    updateReportOpen,
    setUpdateReportOpen,
    reportTitle,
    setReportTitle,
    scheduleCheck,
    setScheduleCheck,
    time,
    setTime,
    reportType,
    setReportType,
    days,
    setDays,
    showUpdateModal,
    reportTypeOpen,
    setReportTypeOpen,
    onReportTypeSubmit,
    onUpdateReportSubmit,
    sharedWithMeData,
    department,
    entity,
    currentDate,
    newDate,
    getSharedUserList,
    sharedUserList,
    removedSharedUser,
    shareReport,
    onSharedUserClick,
    handleSearchData,
    shareWithMeLoading,
    shareWithMeFetching,
    sharedWithMeData,
    shareWithMeData,
    handleSearchDataForShareWithMe,
    refetchShareWithMeData,
  };
}

export default useReports;
