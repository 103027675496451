import React, { Suspense, lazy } from "react";
import plus from "../../assets/icons/plus.svg";
import { Link } from "react-router-dom";
import sparkles_icon from "../../assets/icons/sparkles_icon.svg";
import useSettings from "../settings/useSettings";
import { memo } from "react";
import Loader from "../../components/loader/Loader";
const Header = lazy(() => import("../../components/header/Index"));
function HrQuestions() {
  const { t } = useSettings();
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Header
          heading={t("AdvancedAnalytics.AdvancedAnalytics")}
          subHeading={t("AdvancedAnalytics.GetAnswersToYourBigHRQuestionsAndDriveYourStrategy")}
        />
      </Suspense>
      <div className=" d-flex flex-column flex-row-fluid p-2 px-5  py-10 container-custom ">
        <div className="w-full p-8 flex justify-between flex-wrap">
          <div className="h-[22rem] min-w-[12rem] w-[32.5%] flex flex-col justify-between mt-4">
            <div className="bg-[#0A52A7] rounded-md text-white text-xl font-light h-24 flex items-center justify-center text-center font-poppins">
              <b> {t("AdvancedAnalytics.RecruitmentManagement")}</b>
            </div>
            <div className="bg-white h-[15rem] rounded-lg">
              <div className=" h-24 flex justify-center items-center text-md p-2 text-center  text-[#878787] font-medium border-b-2">
                {t("AdvancedAnalytics.RecruitmentAndEmployeesPerformance")}
              </div>
              <div className=" h-24 flex justify-center items-center text-md p-2 text-center  text-[#878787] font-medium">
                {t("AdvancedAnalytics.RecruitmentRecommendations")}
              </div>
            </div>
          </div>
          <div className="h-[22rem] min-w-[12rem] w-[32.5%] flex flex-col justify-between mt-4">
            <div className="bg-[#0A52A7] rounded-md text-white text-xl font-light h-24 flex items-center justify-center text-center font-poppins">
              <b> {t("AdvancedAnalytics.EmployeesExperience")} </b>
            </div>
            <div className="bg-white h-[15rem] rounded-lg">
              <div className=" h-24 flex justify-center items-center text-md p-2 text-center  text-[#878787] font-medium border-b-2">
                {t("AdvancedAnalytics.DeepUnderstandingOfPeopleTraining")}
              </div>
              <div className=" h-24 flex justify-center items-center text-md p-2 text-center  text-[#878787] font-medium">
                {t(
                  "AdvancedAnalytics.DeepCorrelationAnalyticsBetweenExperience&EmployeeSatisfaction(E-NPS)"
                )}
              </div>
            </div>
          </div>
          <div className="h-[22rem] min-w-[12rem] w-[32.5%] flex flex-col justify-between mt-4">
            <div className="bg-[#0A52A7] rounded-md text-white text-xl font-light h-24 flex items-center justify-center text-center font-poppins">
              <b>{t("AdvancedAnalytics.AttritionPrevention")}</b>
            </div>
            <div className="bg-white h-[15rem] rounded-lg">
              <div className=" h-24 flex justify-center items-center text-md p-2 text-center  text-[#0A52A7] font-medium border-b-2">
                <Link to={"deepanalysis"}>
                  <button>
                    {" "}
                    {t("AdvancedAnalytics.DeepAnalysisOfEmployeesAttrition")}
                  </button>
                </Link>
              </div>
              <div className=" h-24 flex justify-center items-center text-md p-2 text-center  text-[#878787] font-medium">
                {t("AdvancedAnalytics.AttritionModelling")}
              </div>
            </div>
          </div>
          <div className="h-[22rem] min-w-[12rem] w-[32.5%] flex flex-col justify-between mt-4">
            <div className="bg-[#0A52A7] rounded-md text-white text-xl font-light h-24 flex items-center justify-center text-center font-poppins">
              <b> {t("AdvancedAnalytics.FinancialManagement")}</b>
            </div>
            <div className="bg-white h-[15rem] rounded-lg">
              <div className=" h-24 flex justify-center items-center text-md p-2 text-center  text-[#878787] font-medium border-b-2">
                {t("AdvancedAnalytics.DeepCompensationAnalysis")}
              </div>
              <div className=" h-24 flex justify-center items-center text-md p-2 text-center  text-[#878787] font-medium">
                {t("AdvancedAnalytics.CostUnderstanding")}
              </div>
            </div>
          </div>
          <div className="h-[22rem] min-w-[12rem] w-[32.5%] flex flex-col justify-between mt-4">
            <div className="bg-[#0A52A7] rounded-md text-white text-xl font-light h-24 flex items-center justify-center text-center font-poppins">
              <b> {t("AdvancedAnalytics.Others")}</b>
            </div>
            <div className="bg-white h-[15rem] rounded-lg">
              <div className=" h-24 flex justify-center items-center text-md p-2 text-center  text-[#878787] font-medium border-b-2">
                {t("AdvancedAnalytics.DeepAnalyticsOfAbsenteismImpact")}
              </div>
              <div className=" h-24 flex justify-center items-center text-md p-2 text-center  text-[#878787] font-medium">
                {t("AdvancedAnalytics.DeepUnderstandingOfEmployerBrand")}
              </div>
            </div>
          </div>
          <div className="h-[22rem] min-w-[12rem] bg-white w-[32.5%] flex   text-center justify-center mt-4 flex-wrap">
            <img src={sparkles_icon} alt="sparkels" className="w-12 -mt-10 " />
            <div className="flex flex-col items-center justify-center flex-wrap">
              <img src={plus} alt="plusicon" />
              <b className="text-[#878787] text-md w-48 font-medium">
                {t("AdvancedAnalytics.TitleAI")}
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(HrQuestions);
