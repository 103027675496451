import React, { Suspense, lazy } from "react";
import HrQuestions from "./HrQuestions";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./AdvancedAnalytics.css";
import { useState } from "react";
import useAdvancedAnalytics from "./useAdvancedAnalytics";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { BsChevronDown } from "react-icons/bs";
import { Button } from "@mui/material";
import { DateRange } from "react-date-range";
// theme css file
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import Loader from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
const Basic = lazy(() => import("./Basic"));
const GoFurther = lazy(() => import("./GoFurther"));
const Summary = lazy(() => import("./Summary"));
const Header = lazy(() => import("../../components/header/Index"));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function AdvancedAnalytics() {
  const {
    t,
    timePeriodTitle,
    open,
    setOpen,
    handleClickAway,
    onTimePeriodSubmit,
    handleCustomDateSubmite,
    dateModel,
    setDateModel,
    customDate,
    setCustomDate,
    data,
    entityList,
    handleEntityChange,
    isSelectAllEntity,
    entityFilterOpen,
    setEntityFilterOpen,
    onSelectAllEntity,
    entity,
    onEntityFilterSubmite,
    onSubmit,
    basicData,
    ageDistributionData,
    sexRepartitionData,
    showAnalysis,
    bubbleChartSeries,
    departureCauseData,
    tenureDistributionData,
    contractTypeData,
    managerHeadcountData,
    goFurtherData,
    BU_AttritionData,
    absenteeism,
    SummaryData,
  } = useAdvancedAnalytics();
  // console.log("basicData",basicData)

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCustomDateRange = (item) => {
    setCustomDate([item.selection]);
  };
  let sortDataForcorrelation = goFurtherData?.correlation?.sort((a, b) =>
    a.data > b.data ? -1 : 1
  );
  let navigate = useNavigate();
  return (
    <div>
      {/* <HrQuestions /> */}
      <Suspense fallback={<Loader />}>
        <Header
          heading={t("AdvancedAnalytics.title")}
          subHeading={t("AdvancedAnalytics.sub_title")}
        />
      </Suspense>
      <div className="flex items-center my-10 text-gray-400">
        <IoIosArrowRoundBack className="w-12 text-2xl" />
        <button onClick={() => navigate(-1)}>Back to the list</button>
      </div>
      <div className="w-full flex justify-center px-5  container-custom">
        <div className=" flex flex-col w-[100%] justify-center">
          <div className="w-full flex items-end justify-end"></div>
          <div className="flex justify-between">
            <div className="w-[30%] ml-2">
              <div className="bg-white rounded-sm font-semibold w-full h-9 flex justify-center items-center">
                <h6>{t("AdvancedAnalytics.AnalysisScope")}</h6>
              </div>
              <div className="mt-2">
                <div className="w-full h-28 bg-white flex flex-col justify-center items-center border-b-2 ">
                  <h6 className="font-medium">{t("Data_Explorer.entity")}</h6>
                  <div className=" mt-2 h-9 flex justify-center items-center">
                    <ClickAwayListener
                      onClickAway={() => handleClickAway("entityFilter")}
                    >
                      <Box className="" sx={{ position: "relative" }}>
                        <Box
                          onClick={() => setEntityFilterOpen(!entityFilterOpen)}
                          className="bg-gray-100 border h-9 pl-4 pr-4 rounded-md  mt-2  w-auto min-w-[50%] min-h-[40px] flex justify-center items-center "
                        >
                          <button type="button">
                            <span>
                              {isSelectAllEntity ? (
                                <>{t("AdvancedAnalytics.AllSelected")}</>
                              ) : (
                                <>{t("userManagement.SelectValues")}</>
                              )}
                            </span>
                          </button>
                          <BsChevronDown
                            onClick={() =>
                              setEntityFilterOpen(!entityFilterOpen)
                            }
                            className={`${
                              entityFilterOpen && "rotate-180"
                            } ml-10`}
                          />
                        </Box>
                        {entityFilterOpen ? (
                          <>
                            <div className="flex absolute p-2  flex-col w-[260px] border-[1px] rounded-md items-center shadow-md shadow-gray-300 z-40 bg-white">
                              <div className="w-full p-3">
                                <div className="flex flex-col pl-2 text-lg">
                                  <span>
                                    {" "}
                                    <input
                                      type="checkbox"
                                      onChange={onSelectAllEntity}
                                      checked={isSelectAllEntity}
                                      name="entity"
                                    />{" "}
                                    <b>
                                      {" "}
                                      {t("Data_Explorer.select_all_entity")}
                                    </b>
                                  </span>
                                  {entityList?.map((item, i) => {
                                    return (
                                      <span key={i}>
                                        {" "}
                                        <input
                                          type="checkbox"
                                          value={item.entity_name}
                                          onChange={handleEntityChange}
                                          checked={entity.includes(
                                            item.entity_name
                                          )}
                                          defaultChecked={entity.includes(
                                            item.entity_name
                                          )}
                                          name="entity"
                                        />{" "}
                                        {item.entity_name}
                                      </span>
                                    );
                                  })}
                                </div>

                                <div className="flex w-full justify-end ">
                                  {/* <div className="flex w-2/3 justify-around gap-1">
                                    <Button
                                      onClick={() => setEntityFilterOpen(false)}
                                      variant="outlined"
                                      size="small"
                                      color="error"
                                    >
                                      {t("global.cancel")}
                                    </Button>{" "}
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      onClick={onEntityFilterSubmite}
                                    >
                                      {t("global.submit")}
                                    </Button>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </Box>
                    </ClickAwayListener>
                  </div>
                </div>
                <div className="w-full h-28 bg-white flex flex-col justify-center items-center border-b-2 ">
                  <h6 className="pb-2">
                    <h6 className="font-medium">
                      {t("AdvancedAnalytics.period")}
                    </h6>
                  </h6>
                  <div className=" h-9 flex justify-center items-center">
                    <ClickAwayListener
                      onClickAway={() => handleClickAway("timePeriod")}
                    >
                      <Box className="" sx={{ position: "relative" }}>
                        <Box
                          onClick={() => setOpen(!open)}
                          className="bg-gray-100 border h-9 pl-4 pr-4 rounded-md  mt-2  w-auto min-w-[50%] min-h-[40px] flex justify-center items-center "
                        >
                          <button type="button">
                            <span>{timePeriodTitle}</span>
                          </button>
                          <BsChevronDown
                            onClick={() => setOpen(!open)}
                            className={`${open && "rotate-180"} ml-10`}
                          />
                        </Box>
                        {open ? (
                          <Box className="flex absolute p-2 flex-col w-[150px] border-[1px] rounded-md items-center shadow-md z-40 bg-white shadow-gray-300">
                            <button
                              onClick={() => onTimePeriodSubmit(1)}
                              className="bg-white hover:bg-slate-300  w-full text-gray-500 py-1 font-semibold"
                            >
                              {t("snapshot.filter.Today")}
                            </button>
                            <button
                              onClick={() => onTimePeriodSubmit(2)}
                              className="bg-white hover:bg-slate-300  w-full text-gray-500 py-1 font-semibold"
                            >
                              {t("snapshot.filter.Last_30_days")}
                            </button>
                            <button
                              onClick={() => onTimePeriodSubmit(3)}
                              className="bg-white hover:bg-slate-300  w-full text-gray-500 py-1 font-semibold"
                            >
                              {t("snapshot.filter.Last_3_months")}
                            </button>
                            <button
                              onClick={() => onTimePeriodSubmit(4)}
                              className="bg-white hover:bg-slate-300  w-full text-gray-500 py-1 font-semibold"
                            >
                              {t("snapshot.filter.Last_6_months")}
                            </button>
                            <button
                              onClick={() => onTimePeriodSubmit(5)}
                              className="bg-white hover:bg-slate-300  w-full text-gray-500 py-1 font-semibold"
                            >
                              {t("snapshot.filter.Last_12_months")}
                            </button>
                            <button
                              onClick={() => onTimePeriodSubmit(6)}
                              className="bg-white hover:bg-slate-300  w-full text-gray-500 py-1 font-semibold"
                            >
                              {t("snapshot.filter.Custom")}
                            </button>
                          </Box>
                        ) : null}
                      </Box>
                    </ClickAwayListener>
                  </div>
                </div>
                <div className="w-full h-32 bg-white flex flex-col justify-around items-center border-b-2">
                  <button
                    className={`border-black ${
                      entity?.length === 0 ? "bg-[#0A52A7]" : "bg-[#0A52A7]"
                    }  text-white font-semibold rounded-md w-20 h-9 flex justify-center items-center`}
                    onClick={onSubmit}
                    disabled={entity?.length === 0 ? true : false}
                  >
                    {t("AdvancedAnalytics.Run")}
                  </button>
                </div>
              </div>
            </div>
            <div className=" w-[67%] h-full flex  ">
              {showAnalysis ? (
                <div className="w-full bg-white rounded-md flex items-center justify-center">
                  <p> {t("AdvancedAnalytics.SelectScopeToStart")}</p>
                </div>
              ) : (
                <>
                  {basicData?.noOfDepartures != 0 ? (
                    <>
                      <Box sx={{ width: "100%" }}>
                        <Box className="ml-6 mb-5 w-full tab-section custom-tab ">
                          <Tabs
                            textColor="none"
                            indicatorColor="none"
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            className="advancedTabs"
                          >
                            <Tab
                              label={t("AdvancedAnalytics.Basic")}
                              {...a11yProps(0)}
                            />
                            <Tab
                              label={t("AdvancedAnalytics.GoFurther")}
                              {...a11yProps(1)}
                            />
                            <Tab
                              label={t("AdvancedAnalytics.InsightsSummary")}
                              {...a11yProps(2)}
                            />
                          </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                          <Suspense fallback={<Loader />}>
                            <Basic
                              sexRepartitionData={sexRepartitionData}
                              ageDistributionData={ageDistributionData}
                              basicData={basicData}
                              bubbleChartSeries={bubbleChartSeries}
                              departureCauseData={departureCauseData}
                              tenureDistributionData={tenureDistributionData}
                              contractTypeData={contractTypeData}
                              managerHeadcountData={managerHeadcountData}
                              absenteeism={absenteeism}
                            />
                          </Suspense>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <Suspense fallback={<Loader />}>
                            <GoFurther
                              goFurtherData={goFurtherData}
                              BU_AttritionData={BU_AttritionData}
                              basicData={basicData}
                              sortDataForcorrelation={sortDataForcorrelation}
                            />
                          </Suspense>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <Suspense fallback={<Loader />}>
                            <Summary
                              SummaryData={SummaryData}
                              sortDataForcorrelation={sortDataForcorrelation}
                            />
                          </Suspense>
                        </TabPanel>
                      </Box>
                    </>
                  ) : (
                    <div className="w-full bg-white flex flex-col  items-center  h-[400px]  justify-center rounded-md">
                      <b>
                        {t("AdvancedAnalytics.basic.no_departures_matching_p1")}
                        ,{" "}
                      </b>
                      <b>
                        {t("AdvancedAnalytics.basic.no_departures_matching_p2")}
                      </b>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {dateModel ? (
        <>
          {" "}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50">
            <div className=" bg-white pb-5  border border-black rounded-md flex flex-col items-center justify-center">
              <div className="w-full ">
                <div className="w-full flex items-end justify-end ">
                  <span
                    onClick={() => setDateModel(false)}
                    className="pr-3 text-2xl cursor-pointer hover:text-red-700 "
                  >
                    x
                  </span>
                </div>
                <div>
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleCustomDateRange}
                    // (item) => setCustomDate([item.selection])
                    moveRangeOnFirstSelection={false}
                    ranges={customDate}
                  />
                </div>
                <div className="flex w-full justify-end ">
                  <div className="flex w-1/2 justify-around">
                    <Button
                      onClick={() => setDateModel(false)}
                      variant="outlined"
                      size="small"
                      color="error"
                    >
                      {t("global.cancel")}
                    </Button>{" "}
                    <Button
                      onClick={handleCustomDateSubmite}
                      variant="outlined"
                      size="small"
                    >
                      {t("global.submit")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default AdvancedAnalytics;
