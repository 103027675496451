import React, { useEffect, useState } from "react";
import { lazy } from "react";
import { Suspense } from "react";
import Loader from "../../../components/loader/Loader";
import { ReactComponent as SearchIcon } from "../../../assets/icons/SearchIcon.svg";
import { RiDeleteBinLine } from "react-icons/ri";
// import delete from "../../assets/icons/Delete.svg";
import { ReactComponent as Delete } from "../../../assets/icons/Delete.svg";
import useReports from "../useReports";
import { Link } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import { BsChevronDown } from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";

const Header = lazy(() => import("../../../components/header/Index"));

function Reports() {
  const {
    t,
    data,
    isLoading,
    isFetching,
    useMyReports,
    ReportShareOpen,
    setReportShareOpen,
    myReportsData,
    handleDeleteReportConfirmation,
    showDeleteModal,
    setShowDeleteModal,
    onDeleteReportSubmit,
    updateReportOpen,
    setUpdateReportOpen,
    reportTitle,
    setReportTitle,
    scheduleCheck,
    setScheduleCheck,
    time,
    setTime,
    reportType,
    setReportType,
    days,
    setDays,
    showUpdateModal,
    reportTypeOpen,
    setReportTypeOpen,
    onReportTypeSubmit,
    onUpdateReportSubmit,
    getSharedUserList,
    sharedUserList,
    removedSharedUser,
    shareReport,
    onSharedUserClick,
    handleSearchData,
    nextPage,
    currentPage,
    changeCurrPage,
    prePage,
    reports,
    numbers,
    refetch,
  } = useReports();
  const initialValues = {
    email: "",
  };
  const shareReportSchema = Yup.object({
    email: Yup.string()
      .trim("The email cannot include spaces.")
      .strict(true)
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email must be a valid email."
      )
      .ensure()
      .email("Email must be a valid email.")
      .required("Please enter email."),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: shareReportSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      shareReport({
        sharedUserIds: [values.email],
      });
      setReportShareOpen(false);

      action.resetForm();
    },
  });
  useEffect(() => {
    refetch();
  }, []);
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Header
          heading={t("Report.title")}
          subHeading={t("Report.sub_title")}
        />
      </Suspense>
      {!isLoading && !isFetching ? (
        data?.length > 0 ? (
          <div className="w-full h-screen px-5  py-10 container-custom">
            <div className="flex justify-between">
              <span className="flex bg-white w-2/5 rounded-md items-center pl-3">
                <SearchIcon className="w-12" />{" "}
                <input
                  placeholder="Search a report"
                  className="flex grow h-12 "
                  onChange={handleSearchData}
                />
              </span>
              <Link to="/dataexplorer">
                <button className="flex items-center justify-center gap-1 font-medium  rounded-md ml-1  py-2 px-3 bg-[#3DCC4E] text-white ">
                  {t("Report.New_Reports")}
                </button>
              </Link>
            </div>
            <div className="w-full  rounded-xl  flex flex-col   overflow-auto mt-5">
              <table className="min-w-full text-left text-sm font-light  border-separate  border-spacing-y-2 ">
                <tr className=" rounded-md  border-none  sticky top-0 bg-white">
                  <th
                    scope="col"
                    className="px-6 py-4 text-[#0A52A7] font-medium"
                  >
                    {t("Report.Report_name")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-[#0A52A7] font-medium"
                  >
                    {t("Report.Metric")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-[#0A52A7] font-medium"
                  >
                    {t("Report.Created_date")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-[#0A52A7] font-medium"
                  >
                    {t("Report.Last_update")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-[#0A52A7] font-medium"
                  >
                    {t("Report.Last_seen")}
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tbody>
                  {reports?.map((report) => {
                    return (
                      <tr
                        key={report.id}
                        className=" rounded-3xl border-none bg-white"
                      >
                        <Link to={`/myreports/${report.id}`}>
                          <td className=" px-6 py-2 font-semibold min-w-[300px] ">
                            {report.name}
                          </td>
                        </Link>
                        <td className=" px-6 py-1">{report.metric}</td>
                        <td className=" px-6 py-1">{report.createdAt}</td>
                        <td className=" px-6 py-1">{report.updatedAt}</td>
                        <td className="whitespace-nowrap px-6 py-1 ">
                          {report.lastSeen ? report.lastSeen : "No last seen"}{" "}
                        </td>
                        <td className="whitespace-nowrap px-6 py-1 ">
                          <button
                            onClick={() => showUpdateModal(report)}
                            className="font-semibold text-[#0A52A7] text-xs ml-1"
                          >
                            {t("Report.Update")}
                          </button>
                        </td>
                        <td className=" px-6 py-1 ">
                          {" "}
                          <Delete
                            className="text-3xl cursor-pointer w-[20px]"
                            onClick={() =>
                              handleDeleteReportConfirmation(report.id)
                            }
                          />
                        </td>
                        <td className=" px-6 py-1 ">
                          <button
                            onClick={() => onSharedUserClick(report.id)}
                            className="flex items-center justify-center gap-1 font-medium rounded-md ml-1  py-2 px-3 bg-[#0A52A7] text-white capitalize"
                          >
                            {t("global.Share")}
                          </button>
                        </td>
                        <td className="whitespace-nowrap px-6 py-1">
                          {" "}
                          <Link to={`/myreports/${report.id}`}>
                            <button className="flex items-center justify-center gap-1 font-medium  rounded-md ml-1  py-2 px-3 bg-[#0A52A7] text-white">
                              {t("Report.View_report")}
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {ReportShareOpen ? (
                <div className="w-full h-full flex items-center justify-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50 ">
                  <div className="w-1/3  p-4 absolute bg-[#E9EEF5]">
                    <div className=" bg-white rounded-md">
                      <div className=" h-16 flex items-center px-6 justify-between">
                        <p className="text-2xl  font-semibold">
                          {t("Report.Share_the_report")}
                        </p>
                        <button
                          onClick={() => setReportShareOpen(false)}
                          class="pr-3 text-2xl cursor-pointer hover:text-red-700 "
                        >
                          x
                        </button>
                      </div>
                      <hr />
                      <form onSubmit={handleSubmit}>
                        <div className="flex flex-col  justify-around ">
                          <div className="h-24 flex flex-col items-center justify-center mx-6  bg-[#F5F5F5]">
                            <p className="font-medium text-xl">
                              {t("Report.Add_People")}
                            </p>
                            <input
                              type="text"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="existing or new users ('Guest')"
                              className="w-full text-center bg-transparent"
                            />
                            {errors.email && touched.email ? (
                              <p className="text-red-600 text-sm">
                                {errors.email}
                              </p>
                            ) : null}
                          </div>
                          <div className="flex items-center w-full justify-center space-x-2 mt-4">
                            <input type="checkbox" name="" id="" />
                            <span>
                              {t("Report.Share_also_the_future_update_reports")}
                            </span>
                          </div>
                          {sharedUserList && (
                            <div className="px-4 mt-8">
                              <p className="font-semibold">
                                {t("Report.People_you_shared_this_report_with")}{" "}
                              </p>
                              <table className="min-w-full h-auto border-separate border-spacing-y-4">
                                {sharedUserList?.map((user) => {
                                  return (
                                    <tr className="">
                                      <td>
                                        {
                                          user.employees_more_datum
                                            .display_full_name
                                        }
                                      </td>
                                      <td>
                                        <span className="flex items-center gap-2 justify-end">
                                          <Delete
                                            onClick={() =>
                                              removedSharedUser(user.id)
                                            }
                                            className="text-3xl cursor-pointer w-5"
                                          />
                                          Remove access{" "}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                                {/* <tr className="">
                            <td>Benjamin Berriot</td>
                            <td>
                              <span className="flex items-center gap-2 justify-end">
                                <Delete className="text-3xl cursor-pointer w-5" />
                                Remove access{" "}
                              </span>
                            </td>
                          </tr> */}
                              </table>
                            </div>
                          )}
                          <div className="flex flex-col items-center my-4 ">
                            <button
                              type="submit"
                              className="flex items-center justify-center gap-1 font-semibold rounded-md ml-2 mt-4  mb-2 py-2 px-6 bg-[#0A52A7] text-white capitalize"
                            >
                              {t("Report.send")}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>{" "}
                </div>
              ) : null}
            </div>
            {numbers.length > 1 && (
              <div className="w-full flex items-end justify-end">
                <div className="my-9 mr-4 flex items-center justify-center">
                  <button
                    className="flex items-center justify-center gap-1 font-semibold rounded-md ml-2 mt-4  mb-4 py-1.5 px-6 bg-[#0A52A7] text-white capitalize"
                    onClick={prePage}
                    disabled={currentPage === 1}
                  >
                    Back
                  </button>
                  {numbers?.map((num) => {
                    return (
                      <button
                        className={`w-9 h-9 ml-2 border rounded-md ${
                          currentPage === num
                            ? "bg-[#0A52A7]  text-white"
                            : "bg-white  text-black"
                        } text-sm`}
                        onClick={() => changeCurrPage(num)}
                      >
                        {num}
                      </button>
                    );
                  })}
                  <button
                    className="ml-2 flex items-center justify-center gap-1 font-semibold rounded-md mt-4  mb-4 py-1.5 px-6 bg-[#0A52A7] text-white capitalize"
                    onClick={nextPage}
                    disabled={currentPage === numbers[numbers.length - 1]}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="w-full h-screen flex flex-col mt-60 items-center">
              <div className="bg-white h-20 -mt-10 rounded-md w-1/2">
                <p className="font-medium py-4 px-20 text-center text-gray-600">
                  {t("Report.reportMsg1")}, <br />
                  {t("Report.reportMsg2")}
                </p>
              </div>
              <Link to="/dataexplorer">
                <button className="flex items-center justify-center gap-1 font-medium  rounded-md mt-6  py-2 px-3 bg-[#3DCC4E] text-white ">
                  {t("Report.New_Reports")}
                </button>
              </Link>
            </div>
          </>
        )
      ) : (
        <div className="w-full h-screen flex flex-col mt-60 items-center">
          <Loader />
        </div>
      )}
      {showDeleteModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-base font-semibold">Delete Report</h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className=" text-slate-500 text-sm leading-relaxed">
                    Are you sure you want to delete this report ?
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-[#f06c6c] text-white active:bg-emerald-600 font-medium uppercase text-sm px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onDeleteReportSubmit}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {updateReportOpen ? (
        <div className="w-full h-full flex items-center justify-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50 ">
          <div className="w-1/3  bg-white absolute rounded-2xl pb-4">
            <div className="flex items-center py-4 px-6 justify-between">
              <p className="text-2xl  font-semibold">Update the report</p>
              <button
                onClick={() => setUpdateReportOpen(false)}
                className="pr-3 text-2xl cursor-pointer hover:text-red-700 "
              >
                x
              </button>
            </div>

            <hr />
            <div className="flex flex-col  justify-around mt-4 ">
              <div className="h-24 flex flex-col items-start justify-center mx-6  ">
                <label className="font-medium text-xl ">Name</label>
                <input
                  className="bg-[#F5F5F5] py-2 w-full my-2"
                  value={reportTitle}
                  onChange={(e) => setReportTitle(e.target.value)}
                />
                {!reportTitle && (
                  <span className="ml-2 mb-3 text-red-600">
                    Please select some name for report.
                  </span>
                )}
              </div>
              <div className="flex items-center w-full justify-center space-x-2 mb-4">
                <input
                  className=""
                  type="checkbox"
                  name=""
                  id=""
                  checked={scheduleCheck}
                  onChange={(e) => setScheduleCheck(e.target.checked)}
                />
                <span>
                  {t(
                    "Data_Explorer.Schedule_automated_generation_of_the_report"
                  )}
                </span>
              </div>
              {scheduleCheck && (
                <table className="mt-4">
                  <tbody>
                    <tr>
                      <td className="text-end w-2/5">
                        {" "}
                        <lable className="text-end font-semibold">
                          Generate reports
                        </lable>
                      </td>
                      <td>
                        {" "}
                        <div className="w-full flex flex-col justify-center items-center ">
                          <div
                            onClick={() => setReportTypeOpen(!reportTypeOpen)}
                            className="border w-[220px]  mt-2  h-9 flex justify-center items-center"
                          >
                            <ClickAwayListener
                              onClickAway={() => setReportTypeOpen(false)}
                            >
                              <Box
                                className="w-full"
                                sx={{ position: "relative" }}
                              >
                                <Box className="w-full cursor-pointer flex items-center justify-center">
                                  <button type="button">
                                    <span className="flex items-center justify-between capitalize">
                                      <span>{reportType}</span>
                                    </span>
                                  </button>
                                  <BsChevronDown
                                    className={`${
                                      reportTypeOpen && "rotate-180"
                                    } ml-10`}
                                  />
                                </Box>
                                {reportTypeOpen ? (
                                  <Box className="flex absolute top-8 left-14 flex-col w-[150px] border-[1px] rounded-md items-center shadow-md z-40 bg-white shadow-gray-300">
                                    <button
                                      onClick={() =>
                                        onReportTypeSubmit("weekly")
                                      }
                                      className="bg-white hover:bg-slate-300  w-full text-gray-500 py-1 font-semibold capitalize"
                                    >
                                      weekly
                                    </button>
                                    <button
                                      onClick={() =>
                                        onReportTypeSubmit("monthly")
                                      }
                                      className="bg-white hover:bg-slate-300  w-full text-gray-500 py-1 font-semibold capitalize"
                                    >
                                      monthly
                                    </button>
                                    <button
                                      onClick={() =>
                                        onReportTypeSubmit("quarterly")
                                      }
                                      className="bg-white hover:bg-slate-300  w-full text-gray-500 py-1 font-semibold capitalize"
                                    >
                                      quarterly
                                    </button>
                                  </Box>
                                ) : null}
                              </Box>
                            </ClickAwayListener>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end pt-4">
                        <lable className="w-1/4 text-end font-semibold">
                          on
                        </lable>
                      </td>
                      {reportType == "weekly" ? (
                        <td className="pt-4">
                          {" "}
                          <div className="flex items-center ml-2">
                            <input
                              type="checkbox"
                              onChange={() => setDays("Mon")}
                              checked={days === "Mon"}
                              className="border w-5 mt-1"
                            />
                            <p className="text-sm font-semibold">M</p>
                            <input
                              type="checkbox"
                              onChange={() => setDays("Tue")}
                              checked={days === "Tue"}
                              className="border w-5 mt-1"
                            />
                            <p className="text-sm font-semibold">T</p>
                            <input
                              type="checkbox"
                              onChange={() => setDays("Wed")}
                              checked={days === "Wed"}
                              className="border w-5 mt-1"
                            />
                            <p className="text-sm font-semibold">W</p>
                            <input
                              type="checkbox"
                              onChange={() => setDays("Thu")}
                              checked={days === "Thu"}
                              className="border w-5 mt-1"
                            />
                            <p className="text-sm font-semibold">T</p>
                            <input
                              type="checkbox"
                              onChange={() => setDays("Fri")}
                              checked={days === "Fri"}
                              className="border w-5 mt-1"
                            />
                            <p className="text-sm font-semibold">F</p>
                            <input
                              type="checkbox"
                              onChange={() => setDays("Sat")}
                              checked={days === "Sat"}
                              className="border w-5 mt-1"
                            />
                            <p className="text-sm font-semibold">S</p>
                            <input
                              type="checkbox"
                              onChange={() => setDays("Sun")}
                              checked={days === "Sun"}
                              className="border w-5 mt-1"
                            />
                            <p className="text-sm font-semibold">S</p>
                          </div>
                        </td>
                      ) : null}
                      {reportType == "monthly" ? (
                        <td className="pt-4">
                          {" "}
                          <div className="flex items-center ml-2 flex-wrap space-x-1 space-y-1">
                            <p className="text-sm font-semibold flex items-center ">
                              <input
                                type="checkbox"
                                onChange={() => setDays("1st day")}
                                checked={days === "1st day"}
                                className="border w-5 "
                              />
                              1st day
                            </p>

                            <p className="text-sm font-semibold flex items-center">
                              <input
                                type="checkbox"
                                onChange={() => setDays("5th day")}
                                checked={days === "5th day"}
                                className="border w-5 "
                              />
                              5th day
                            </p>

                            <p className="text-sm font-semibold flex items-center">
                              {" "}
                              <input
                                type="checkbox"
                                onChange={() => setDays("10th day")}
                                checked={days === "10th day"}
                                className="border w-5 "
                              />
                              10th day
                            </p>

                            <p className="text-sm font-semibold flex items-center">
                              <input
                                type="checkbox"
                                onChange={() => setDays("15th day")}
                                checked={days === "15th day"}
                                className="border w-5 "
                              />
                              15th day
                            </p>

                            <p className="text-sm font-semibold flex items-center">
                              <input
                                type="checkbox"
                                onChange={() => setDays("20th day")}
                                checked={days === "20th day"}
                                className="border w-5 "
                              />
                              20th day
                            </p>

                            <p className="text-sm font-semibold flex items-center">
                              {" "}
                              <input
                                type="checkbox"
                                onChange={() => setDays("25th day")}
                                checked={days === "25th day"}
                                className="border w-5 "
                              />
                              25th day
                            </p>

                            <p className="text-sm font-semibold flex items-center">
                              {" "}
                              <input
                                type="checkbox"
                                onChange={() => setDays("last day")}
                                checked={days === "last day"}
                                className="border w-5 "
                              />
                              last day
                            </p>
                          </div>
                        </td>
                      ) : null}
                      {reportType == "quarterly" ? (
                        <td className="pt-4">
                          {" "}
                          <div className="flex items-center ml-2 flex-wrap space-x-1 space-y-1">
                            <p className="text-sm font-semibold flex items-center">
                              <input
                                type="checkbox"
                                onChange={() => setDays("1st day")}
                                checked={days === "1st day"}
                                className="border w-5 "
                              />
                              1st quarter day
                            </p>

                            <p className="text-sm font-semibold flex items-center">
                              {" "}
                              <input
                                type="checkbox"
                                onChange={() => setDays("last day")}
                                checked={days === "last day"}
                                className="border w-5 "
                              />
                              last quarter day
                            </p>
                          </div>
                        </td>
                      ) : null}
                    </tr>
                    <tr>
                      <td className="text-end py-4">
                        <lable className="w-1/4 text-end font-semibold">
                          at
                        </lable>
                      </td>
                      <td className="py-4 w-2/3">
                        {" "}
                        <div className="flex items-center ml-2">
                          <input
                            type="time"
                            className="bg-[#F5F5F5] px-6 py-1 ml-2 "
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              <div className="flex flex-col items-center ">
                <button
                  disabled={!reportTitle}
                  onClick={(e) => onUpdateReportSubmit(reportTitle, e)}
                  className={`flex items-center justify-center gap-1 font-bold border-[1px] border-black rounded-md ml-2 mb-2 py-2 px-6 ${
                    reportTitle ? "bg-[#0A52A7]" : "bg-[#445363]"
                  } bg-[#0A52A7] text-white capitalize`}
                >
                  Save
                </button>
              </div>
            </div>
          </div>{" "}
        </div>
      ) : null}
    </>
  );
}

export default Reports;
