import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useFliterListingForDataExplorer } from "../../hooks/DataExplorer";
import {
  useDataForBasicAdvAnalytics,
  useDataForGoFurtherAdvAnalytics,
  useDataForSummaryAdvAnalytics,
} from "../../hooks/AdvancedAnalytics";

function useAdvancedAnalytics() {
  const { t,i18n } = useTranslation();
  const { data } = useFliterListingForDataExplorer();
  const entityList = data?.Other?.EntityList;

  const {
    mutate: basics,
    data: basicData,
    isLoading: isBasicLoading,
  } = useDataForBasicAdvAnalytics();
  const {
    mutate: goFurther,
    data: goFurtherData,
    isGoFurtherLoading,
  } = useDataForGoFurtherAdvAnalytics();

  const {
    mutate: Summary,
    data: SummaryData,
    isSummaryLoading,
  } = useDataForSummaryAdvAnalytics();
  const [timePeriodTitle, setTimePeriodTitle] = useState(
    t("snapshot.filter.Today")
  );
  const [open, setOpen] = useState(false);
  const [cDate, setCdate] = useState(moment().format("YYYY-MM-DD"));
  const [nDate, setNdate] = useState(moment().format("YYYY-MM-DD"));
  const [dateModel, setDateModel] = useState(false);
  const [customDate, setCustomDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [entity, setEntity] = useState([]);
  const [isSelectAllEntity, setIsSelectAllEntity] = useState(false);
  const [entityFilterOpen, setEntityFilterOpen] = useState(false);
  const [showAnalysis, setShowAnalysis] = useState(true);
  //
  const handleClickAway = (val) => {
    if (val === "entityFilter") {
      setEntityFilterOpen(false);
    } else if (val === "timePeriod") {
      setOpen(false);
    }
  };
  const onTimePeriodSubmit = (val) => {
    let d = moment();
    let cd = moment();
    let newDate;
    let currentDate;
    if (val === 1) {
      newDate = d.format("YYYY-MM-DD");
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);
      // console.log(newDate, currentDate);
      setTimePeriodTitle(t("snapshot.filter.Today"));
    } else if (val === 2) {
      newDate = d.subtract(30, "days").format("YYYY-MM-DD");
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);
      // console.log(newDate, currentDate);
      setTimePeriodTitle(t("snapshot.filter.Last_30_days"));
    } else if (val === 3) {
      newDate = d.subtract(3, "months").format("YYYY-MM-DD");
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);
      // console.log(newDate, currentDate);
      setTimePeriodTitle(t("snapshot.filter.Last_3_months"));
    } else if (val === 4) {
      newDate = d.subtract(6, "months").format("YYYY-MM-DD");
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);
      // console.log(newDate, currentDate);
      setTimePeriodTitle(t("snapshot.filter.Last_6_months"));
    } else if (val === 5) {
      newDate = d.subtract(1, "years").format("YYYY-MM-DD");
      currentDate = cd.format("YYYY-MM-DD");
      setCdate(currentDate);
      setNdate(newDate);
      // console.log(newDate, currentDate);
      setTimePeriodTitle(t("snapshot.filter.Last_12_months"));
    } else if (val === 6) {
      setDateModel(true);
    }
    setOpen((prev) => !prev);
  };
  const handleCustomDateSubmite = () => {
    let endDate = customDate[0].endDate;
    let endDateDay = endDate.getDate();
    let endDateMonth = endDate.getMonth() + 1;
    if (endDateDay < 10) {
      endDateDay = "0" + endDateDay;
    }
    if (endDateMonth < 10) {
      endDateMonth = "0" + endDateMonth;
    }
    let startDate = customDate[0].startDate;
    let startDateDay = startDate.getDate();
    let startDateMonth = startDate.getMonth() + 1;
    if (startDateDay < 10) {
      startDateDay = "0" + startDateDay;
    }
    if (startDateMonth < 10) {
      startDateMonth = "0" + startDateMonth;
    }
    let newDate = `${startDate.getFullYear()}-${startDateMonth}-${startDateDay}`;
    let currentDate = `${endDate.getFullYear()}-${endDateMonth}-${endDateDay}`;
    setNdate(newDate);
    setCdate(currentDate);
    setDateModel(false);
    setTimePeriodTitle(`${newDate} to ${currentDate}`);
  };

  const handleEntityChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setEntity([...entity, value]);
    } else {
      let res = entity.filter((e) => e !== value);
      setEntity(res);
    }
  };
  const onSelectAllEntity = (e) => {
    const { checked } = e.target;
    setIsSelectAllEntity(!isSelectAllEntity);
    if (checked) {
      setEntity(entityList?.map((item) => item.entity_name));
    } else {
      setEntity([]);
    }
  };
  const onEntityFilterSubmite = () => {
    setEntityFilterOpen(false);
  };
  let currentLang = localStorage.getItem("language");
  const onSubmit = () => {
    if (cDate && nDate && entity.length !== 0) {
      setShowAnalysis(false);
      goFurther({ newDate: nDate, currentDate: cDate, entity });
      basics({ newDate: nDate, currentDate: cDate, entity });
      Summary({ newDate: nDate, currentDate: cDate, entity,lang:i18n.language });
    }
  };

  useEffect(() => {
    if (cDate && nDate && entity.length !== 0) {
      Summary({ newDate: nDate, currentDate: cDate, entity,lang:i18n.language });
    }
  }, [currentLang]);

  //Starting for Go Futher Tab Section....

  // starting for Age Distribution Data
  const [ageDistributionData, setAgeDistributionData] = useState({
    series: [],
    options: [],
  });
  const ageDistribution = basicData?.ageDistribution;

  const ageDistributionSeries = ageDistribution?.map((item) => item.nb);
  const ageDistributionOptions = ageDistribution?.map(
    (item) => item.age_bucket
  );
  //   console.log(ageDistributionData);

  // starting for Sex Repartition Data
  const [sexRepartitionData, setSexRepartitionData] = useState({
    series: [],
    options: [],
  });
  const sexRepartition = basicData?.sexRepartition;
  const sexRepartitionSeries = sexRepartition?.map((item) =>
    Number(item.nb_employees_by_sex)
  );
  const sexRepartitionOptions = sexRepartition?.map((item) => item.gender);

  // starting for Business Units Data.

  const [bubbleChartSeries, setBubbleChartSeries] = useState([]);
  const buissnessUnits = basicData?.buissnessUnits?.map((item) => {
    return {
      label: item.department_name,
      value: item.nb_employees_by_department,
    };
  });
  // starting for Departure Cause Data.
  const [departureCauseData, setDepartureCauseData] = useState({
    series: [],
    options: [],
  });
  const departureCause = basicData?.departureCause;
  let departureCauseSeries = departureCause?.map((item) => {
    return Number(item.nb_employees_by_departure_cause);
  });
  let departureCauseOptions = departureCause?.map((item) => {
    return item.termination_origin;
  });

  // Starting for Tenure Distribution Options Data.
  const [tenureDistributionData, setTenureDistributionData] = useState({
    series: [],
    options: [],
  });
  const tenureDistribution = basicData?.TenureDistribution;
  let tenureDistributionSeries = tenureDistribution?.map((item) => {
    return Number(item.nb);
  });
  let tenureDistributionOptions = tenureDistribution?.map((item) => {
    return item.tenure_bucket;
  });

  //Starting for Contract Type ContractType Data.
  const [contractTypeData, setContractTypeData] = useState({
    series: [],
    options: [],
  });
  const contractType = basicData?.ContractType;
  let contractTypeSeries = contractType?.map((item) => {
    return Number(item.nb_employees);
  });
  let contractTypeOptions = contractType?.map((item) => {
    return item.employment_type;
  });

  //Starting for Manager Headcount Data.
  const [managerHeadcountData, setManagerHeadcountData] = useState();
  const managerHeadcount = basicData?.managerHeadcount?.number_of_managers;
  let managerHeadcountSeries = Number(managerHeadcount);

  // starting for absenteeism
  const [absenteeism, setAbsenteeism] = useState({
    series: [],
    options: [],
  });
  let absenteeismOption = basicData?.absenteeism?.map(
    (item) => item.nb_days_off
  );
  let absenteeismSeries = basicData?.absenteeism?.map((item) =>
    Number(item.absenteeism_rate)
  );

  // useEffect for basic tab data
  useEffect(() => {
    if (!isBasicLoading) {
      setAbsenteeism({
        series: absenteeismSeries,
        options: absenteeismOption,
      });
      setSexRepartitionData({
        series: sexRepartitionSeries,
        options: sexRepartitionOptions,
      });
      setAgeDistributionData({
        series: ageDistributionSeries,
        options: ageDistributionOptions,
      });
      setBubbleChartSeries(buissnessUnits);
      setDepartureCauseData({
        series: departureCauseSeries,
        options: departureCauseOptions,
      });
      setTenureDistributionData({
        series: tenureDistributionSeries,
        options: tenureDistributionOptions,
      });
      setContractTypeData({
        series: contractTypeSeries,
        options: contractTypeOptions,
      });
      setManagerHeadcountData(managerHeadcountSeries);
    }
  }, [basicData]);

  //Starting for Go Futher Tab Section....
  // console.log("goFurtherData",goFurtherData);
  const [BU_AttritionData, setBU_AttritionData] = useState({
    department: [],
    attritionRate: [],
    avgHeadcount: [],
  });
  let BU_AttritionDepartment = goFurtherData?.BU_Attrition?.map((item) => {
    return item.department_name;
  });
  // console.log("BU_Attrition",goFurtherData?.BU_Attrition)
  let BU_AttritionAttritionRate = goFurtherData?.BU_Attrition?.map((item) => {
    return item.attrition_rate;
  });
  let BU_AttritionAvgHeadcount = goFurtherData?.BU_Attrition?.map((item) => {
    return item.avg_headcount;
  });

  useEffect(() => {
    if (!isGoFurtherLoading) {
      setBU_AttritionData({
        department: BU_AttritionDepartment,
        attritionRate: BU_AttritionAttritionRate,
        avgHeadcount: BU_AttritionAvgHeadcount,
      });
    }
  }, [goFurtherData]);

  useEffect(() => {
    if (entity?.length === entityList?.length) {
      setIsSelectAllEntity(true);
    } else {
      setIsSelectAllEntity(false);
    }
  }, [entity]);
  return {
    t,
    timePeriodTitle,
    open,
    setOpen,
    handleClickAway,
    onTimePeriodSubmit,
    dateModel,
    setDateModel,
    customDate,
    setCustomDate,
    handleCustomDateSubmite,
    data,
    entityList,
    handleEntityChange,
    isSelectAllEntity,
    entityFilterOpen,
    setEntityFilterOpen,
    onSelectAllEntity,
    entity,
    onEntityFilterSubmite,
    onSubmit,
    cDate,
    nDate,
    basicData,
    ageDistributionData,
    sexRepartitionData,
    showAnalysis,
    bubbleChartSeries,
    departureCauseData,
    tenureDistributionData,
    contractTypeData,
    managerHeadcountData,
    goFurtherData,
    BU_AttritionData,
    absenteeism,
    SummaryData,
  };
}

export default useAdvancedAnalytics;
