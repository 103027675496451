import { useQuery, useMutation, useQueryClient } from "react-query";

import { Navigate, useNavigate } from "react-router-dom";
import { changePassword, getUserConfirm, login } from "../api/auth";

import { toast } from "react-toastify";
import { useCurrentUserProfile } from "./user";
export const useLogin = () => {
  const navigate = useNavigate();
const {refetch}=useCurrentUserProfile()
  const {
    mutate,
    isLoading,
    error,
    data: loginData,
  } = useMutation(["user"], (userInfo) => login(userInfo), {
    onSuccess: (data) => {
      navigate("/", { replace: true });
      refetch()
      toast.success("Login successfully.", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    onError: (error) => {
      // Show an error toast
      toast.error("Invalid credentials.", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  return { mutate, isLoading, error };
};

export const useChangePassword = () => {
  const navigate = useNavigate();

  const { mutate, isLoading, error } = useMutation(
    ["password"],
    (userInfo) => changePassword(userInfo),
    {
      onSuccess: (data) => {
        // Show a success toast
        toast.success("Password change successfully.", {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/settings", { replace: true });
      },
      onError: (error) => {
        // Show an error toast
        toast.error("Old password does not matched.", {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useUserConfirm = () => {
  const navigate = useNavigate();

  const { mutate, isLoading, error } = useMutation(
    ["user-confirm"],
    (userInfo) => getUserConfirm(userInfo),
    {
      onSuccess: () => {
        navigate("/login", { replace: true });
        // Show an success toast
        toast.success("Password set successfully", {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(error.response.data.msg, {
          // position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { mutate, isLoading, error };
};
