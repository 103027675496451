import React from "react";
import * as Yup from "yup";
import { useUserConfirm } from "../hooks/Auth";

function useSetPasswordPage() {
  const { mutate: confirmPassword } = useUserConfirm();
  const signUpSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters.")
      .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character.")
      .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character.")
      .matches(/^(?=.*[0-9])/, "Must contain at least one number.")
      .matches(/^(?=.*[!@#%&])/, "Must contain at least one special character.")
      .required("Please enter your new password."),
    cPassword: Yup.string()
      .required("Please enter your confirm password")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with  password."
      ),
  });
  const initialValues = {
    password: "",
    cPassword: "",
  };
  return { confirmPassword, signUpSchema, initialValues };
}

export default useSetPasswordPage;
