import React from "react";
import useDataExplorer from "./useDataExplorer";
import { lazy } from "react";
import { Suspense } from "react";
import Loader from "../../components/loader/Loader";
import { memo } from "react";
const FilterDataExplorer = lazy(() => import("./FilterDataExplorer"));
const Header = lazy(() => import("../../components/header/Index"));

function DataExplorer() {
  const { t } = useDataExplorer();

  return (
    <div>
      <Suspense fallback={<Loader />}>
        <Header
          heading={t("Data_Explorer.Data_Visualization")}
          subHeading={t("Data_Explorer.sub_title")}
        />
      </Suspense>
      <main className=" d-flex flex-column flex-row-fluid p-2  px-5  py-10 container-custom">
        <Suspense fallback={<Loader />}>
          <FilterDataExplorer />
        </Suspense>
      </main>
    </div>
  );
}

export default memo(DataExplorer);
