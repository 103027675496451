import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import useLoginPage from "./useLoginPage";
import logo from "../assets/icons/logo.svg";
import { memo } from "react";
function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    let storage = localStorage.getItem("token");
    if (storage) {
      navigate("/");
    }
  });

  const { login, signUpSchema, initialValues } = useLoginPage();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        login(values); //login via email password
        action.resetForm();
      },
    });

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-gray-200 ">
      <div>
        <div className="flex justify-center items-center w-full my-5">
          <img src={logo} alt="staffDeskLogo" className="w-[200px]" />
        </div>
        <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-sm pb-12">

          <div className="flex flex-col items-center">
            <h1 className="text-2xl font-semibold text-center ">Welcome Back</h1>
            <p className="text-sm text-gray-400">
              Please sign in with details...
            </p>
          </div>
          <form onSubmit={handleSubmit} className="mt-6">
            <div className="mb-2">
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-gray-800"
              >
                Email
              </label>
              <input
                type="text"
                value={values.email}
                onChange={handleChange}
                name="email"
                onBlur={handleBlur}
                className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              {errors.email && touched.email ? (
                <p className="text-red-600 text-sm">{errors.email}</p>
              ) : null}
            </div>
            <div className="mb-2">
              <label
                htmlFor="password"
                className="block text-sm font-semibold text-gray-800"
              >
                Password
              </label>
              <input
                type="password"
                value={values.password}
                onChange={handleChange}
                name="password"
                autoComplete="off"
                onBlur={handleBlur}
                className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              {errors.password && touched.password ? (
                <p className="text-red-600 text-sm">{errors.password}</p>
              ) : null}
            </div>

            <div className="mt-6">
              <button
                type="submit"
                className="w-full px-4 py-2 tracking-wide text-white text-md font-semibold transition-colors duration-200 transform bg-blue-600 rounded-md hover:text-blue-500 focus:outline-none focus:text-blue-500"
              >
                Sign In
              </button>
              <Link to={'forgotpassword'}>
                <p className="text-blue-600 mt-2">Forgot password ?</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default memo(Login);
