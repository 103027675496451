import React, { useEffect, useState } from "react";
import { lazy } from "react";
import { Suspense } from "react";
import Loader from "../../../components/loader/Loader";
import { ReactComponent as SearchIcon } from "../../../assets/icons/SearchIcon.svg";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import useReports from "../useReports";
const Header = lazy(() => import("../../../components/header/Index"));

function SharedWithMe() {
  const [open, setOpen] = useState(null);
  const {
    t,
    shareWithMeLoading,
    shareWithMeFetching,
    sharedWithMeData,
    shareWithMeData,
    handleSearchDataForShareWithMe,
    nextPage,
    currentPage,
    changeCurrPage,
    prePage,
    sharedWithMeReports,
    numbersForShareWithMe,
    refetchShareWithMeData,
  } = useReports();
  useEffect(() => {
    refetchShareWithMeData();
  }, []);
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Header
          heading={t("Report.Shared_With_Me")}
          subHeading={t("Report.sub_title")}
        />
      </Suspense>
      {!shareWithMeFetching && !shareWithMeLoading ? (
        sharedWithMeData?.length > 0 ? (
          <div className="w-full h-screen px-5  py-10 container-custom">
            <div>
              <span className="flex bg-white w-2/5 rounded-md items-center pl-3">
                <SearchIcon className="w-12" />{" "}
                <input
                  placeholder="Search a report"
                  className="flex grow h-12 "
                  onChange={handleSearchDataForShareWithMe}
                />
              </span>
            </div>
            <div className="w-full  rounded-xl  flex flex-col   overflow-auto mt-5">
              <table className="min-w-full text-left text-sm font-light  border-separate  border-spacing-y-2 ">
                <tr className=" rounded-md  border-none  sticky top-0 bg-white">
                  <th
                    scope="col"
                    className="px-6 py-4 text-[#0A52A7] font-medium"
                  >
                    {t("Report.Report_name")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-[#0A52A7] font-medium"
                  >
                    {t("Report.Metric")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-[#0A52A7] font-medium"
                  >
                    {t("Report.Shared_by")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-[#0A52A7] font-medium"
                  >
                    {t("Report.Last_update")}
                  </th>

                  <th></th>
                </tr>

                <tbody>
                  {sharedWithMeReports?.map((report, i) => {
                    return (
                      <tr
                        key={report.id}
                        className=" rounded-3xl border-none bg-white"
                      >
                        <Link to={`/sharedwithme/${report.report_id}`}>
                          <td className=" px-6 py-2 font-semibold min-w-[300px] ">
                            {report?.create_report?.name}
                          </td>
                        </Link>
                        <td className=" px-6 py-1 whitespace-nowrap">
                          {report?.create_report?.metric}
                        </td>
                        <td className=" px-6 py-1">
                          {report?.SharedBy?.display_full_name}
                        </td>
                        <td className=" px-6 py-1">
                          {report?.create_report?.updatedAt}
                        </td>

                        <td className="  whitespace-nowrap px-6 py-1">
                          {" "}
                          <Link to={`/sharedwithme/${report.report_id}`}>
                            <button className="flex items-center justify-center gap-1 font-bold border-[1px] border-black rounded-md ml-2 py-3 px-3 bg-[#0A52A7] text-white ">
                              {t("Report.View_report")}
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {numbersForShareWithMe?.length > 1 && (
              <div className="w-full flex items-end justify-end">
                <div className="my-9 mr-4 flex items-center justify-center">
                  <button
                    className="flex items-center justify-center gap-1 font-semibold rounded-md ml-2 mt-4  mb-4 py-1.5 px-6 bg-[#0A52A7] text-white capitalize"
                    onClick={prePage}
                    disabled={currentPage === 1}
                  >
                    {t("Report.Back")}
                  </button>
                  {numbersForShareWithMe?.map((num) => {
                    return (
                      <button
                        className={`w-9 h-9 ml-2 border rounded-md ${
                          currentPage === num
                            ? "bg-[#0A52A7]  text-white"
                            : "bg-white  text-black"
                        } text-sm`}
                        onClick={() => changeCurrPage(num)}
                      >
                        {num}
                      </button>
                    );
                  })}
                  <button
                    className="ml-2 flex items-center justify-center gap-1 font-semibold rounded-md mt-4  mb-4 py-1.5 px-6 bg-[#0A52A7] text-white capitalize"
                    onClick={nextPage}
                    disabled={
                      currentPage ===
                      numbersForShareWithMe[numbersForShareWithMe.length - 1]
                    }
                  >
                    {t("Report.Next")}
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full h-screen flex flex-col mt-60 items-center">
            <div className="bg-white h-20 rounded-md w-1/2 flex items-center justify-center">
              <p className="font-medium py-4 px-20 text-center text-gray-600">
                {t("Report.NoReportsFound")}
                <br />
              </p>
            </div>
          </div>
        )
      ) : (
        <div className="w-full  rounded-xl  flex flex-col  max-h-[450px] min-h-[400px] overflow-auto mt-5">
          <Loader />
        </div>
      )}
      {open && (
        <div className="w-full h-screen px-10 py-28 absolute top-24 bg-slate-500"></div>
      )}
    </>
  );
}

export default SharedWithMe;
