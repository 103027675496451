import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useDashboard from "./useDashboard";
import { memo } from "react";
import { lazy } from "react";
import "../../i18n";
import "./index.css";
import { Suspense } from "react";
import Loader from "../../components/loader/Loader";
import useGlobalPermissions from "../../useGlobalPermissions";
import { useNavigate } from "react-router-dom";
const Trend = lazy(() => import("./trend/Index"));
const SnapShot = lazy(() => import("./snapshot/Index"));
const FilterCompTrend = lazy(() => import("./trend/FilterComp"));
const FilterCompSnapshot = lazy(() => import("./snapshot/FilterComp"));
const Header = lazy(() => import("../../components/header/Index"));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Dashboard = ({ moduleAccessPermissions, dataAccessPermissions }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let isDistinct = new Date();

  const [isDistinctMonths, setIsDistinctMonths] = useState(
    isDistinct.getMonth() >= 2 ? true : false
  );

  const { a11yProps, handleChange, value } = useDashboard();
  useEffect(() => {
    if (moduleAccessPermissions["Dashboard"] === false) {
      navigate("/settings");
    } else {
      navigate("/");
    }
  }, [moduleAccessPermissions]);
  return (
    <main className=" d-flex flex-column flex-row-fluid">
      <Suspense fallback={<Loader />}>
        <Header />
      </Suspense>
      <div className="px-5  py-10 container-custom">
        <div className="flex relative">
          <Box sx={{ width: "100%" }}>
            <Box className=" custom-tab">
              <Tabs
                textColor="none"
                indicatorColor="none"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="text-black tabSections  mb-2"
              >
                <Tab className="font-poppins" label={t("snapshot.title")} {...a11yProps(0)} />
                <Tab className="margin-10 font-poppins" label={t("trend.title")} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Suspense fallback={<Loader />}>
                <SnapShot dataAccessPermissions={dataAccessPermissions} />
              </Suspense>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Suspense fallback={<Loader />}>
                <Trend
                  isDistinctMonths={isDistinctMonths}
                  setIsDistinctMonths={setIsDistinctMonths}
                  dataAccessPermissions={dataAccessPermissions}
                />
              </Suspense>
            </TabPanel>
          </Box>
          {value ? (
            <Suspense fallback={<Loader />}>
              <FilterCompTrend
                isDistinctMonths={isDistinctMonths}
                setIsDistinctMonths={setIsDistinctMonths}
              />
            </Suspense>
          ) : (
            <Suspense fallback={<Loader />}>
              <FilterCompSnapshot />
            </Suspense>
          )}
        </div>
      </div>
    </main>
  );
};

export default memo(Dashboard);
