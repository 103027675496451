import React from "react";
import { lazy } from "react";
import { Suspense } from "react";
import Loader from "../../../components/loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useReports from "../useReports";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { IoIosArrowRoundBack } from "react-icons/io";

const Header = lazy(() => import("../../../components/header/Index"));
function ViewReport() {
  const {
    t,
    location,
    viewData,
    barChartData,
    pieChartData,
    valueFormat,
    setValueFormat,
    showValueFormatter,
    showValueFormatterPie,
    setShowValueFormatter,
    Orientation,
    groupby,
    X_Axis,
    attritionMetrics,
    department,
    entity,
    currentDate,
    newDate,
  } = useReports();
  const history = useLocation();
  //starting for pie chart
  let totalOfDataValue = pieChartData?.values?.reduce(
    (acc, curr) => acc + curr,
    0
  );
  let percentData = pieChartData?.values?.map((val) =>
    Number(((val / totalOfDataValue) * 100).toFixed(2))
  );
  const pieChartSeries = valueFormat ? pieChartData?.values : percentData;
  // const pieChartSeries = pieChartData?.values;
  // here pie chart options for % values and percent symbol.
  const pieChartOptionsForPercentageData = {
    labels: pieChartData?.names,
    chart: {
      type: "donut",
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val, { seriesIndex, w }) => {
        return Number(val).toFixed(0) + " %"; 
      }, // <--- HERE
      style: {
        fontSize: "1rem",
      },
    },
    total: {
      show: true,
      showAlways: true,
      label: "Total",
      fontSize: "22px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: 600,
      color: "#373d3f",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              formatter: (w) =>{
                if(totalOfDataValue){
                  return 100 + " %"
                }else{
                  return 0 + " %"
                }

              },
              fontSize: 40,
              color: "#000",
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 10,
          },
          value: {
            show: true,
            fontSize: "16px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            color: undefined,
            offsetY: 16,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    noData: {
      text: t("Data_Explorer.select_filters"),
    },
    colors: [
      "#0A52A7",
      "#3DCC4E",
      "#FEC633",
      "#D52DB7",
      "#FC4F00",
      "#ECF2FF",
      "#D3756B",
      "#FF55BB",
      "#393E46",
    ],
  };
  const pieChartOptionsForHideTotal = {
    labels: pieChartData?.names,
    chart: {
      type: "donut",
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (value, { seriesIndex, w }) => {
        var val = Math.abs(w.config.series[seriesIndex]);
        if (val >= 1000) {
          val = (val / 1000).toFixed(1) + " K";
          return val;
        } else {
          return w.config.series[seriesIndex];
        }
      }, // <--- HERE
      style: {
        fontSize: "1rem",
      },
    },

    plotOptions: {
      pie: {
        donut: {
          // labels: {
          //   show: true,
          //   // total: {
          //   //   show: showValueFormatter ,
          //   //   showAlways: showValueFormatter,
          //   //   formatter: pieChartData?.isCompensationTotal
          //   //     ? function (w) {
          //   //         console.log(
          //   //           "pieChartData?.isCompensationTotal",
          //   //           pieChartData?.isCompensationTotal
          //   //         );
          //   //         let value = w.globals.seriesTotals.reduce((a, b) => {
          //   //           return a + b;
          //   //         }, 0);
          //   //         if (value >= 1000) {
          //   //           return (value / 1000).toFixed(1) + " k€";
          //   //         } else {
          //   //           return value + " €";
          //   //         }
          //   //       }
          //   //     : function (w) {
          //   //         console.log(
          //   //           "pieChartData?.isCompensationTotal",
          //   //           pieChartData?.isCompensationTotal
          //   //         );
          //   //         return w.globals.seriesTotals.reduce((a, b) => {
          //   //           return a + b;
          //   //         }, 0);
          //   //       },
          //   //   fontSize: 40,
          //   //   color: "#000",
          //   // },
          // },
        },
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 10,
          },
          value: {
            show: true,
            fontSize: "16px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            color: undefined,
            offsetY: 16,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    noData: {
      text: t("Data_Explorer.select_filters"),
    },
    colors: [
      "#0A52A7",
      "#3DCC4E",
      "#FEC633",
      "#D52DB7",
      "#FC4F00",
      "#ECF2FF",
      "#D3756B",
      "#FF55BB",
      "#393E46",
    ],
  };
  const pieChartOptions = {
    labels: pieChartData?.names,
    chart: {
      type: "donut",
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (value, { seriesIndex, w }) => {
        var val = Math.abs(w.config.series[seriesIndex]);
        if (val >= 1000) {
          val = (val / 1000).toFixed(1) + " K";
          return val;
        } else {
          return w.config.series[seriesIndex];
        }
      }, // <--- HERE
      style: {
        fontSize: "1rem",
      },
    },

    plotOptions: {
      pie: {
        donut: {
          labels: {
            show:
              true && pieChartData?.values.length > 1
                ? true
                : pieChartData?.values[0] == 0
                ? false
                : true,
            total: {
              show:
                showValueFormatterPie && pieChartData?.values.length > 1
                  ? true
                  : pieChartData?.values[0] == 0
                  ? false
                  : true,
              showAlways:
                showValueFormatterPie && pieChartData?.values.length > 1
                  ? true
                  : pieChartData?.values[0] == 0
                  ? false
                  : true,
              formatter: pieChartData?.isCompensationTotal
                ? function (w) {
                    let value = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                    if (value >= 1000) {
                      return (value / 1000).toFixed(1) + " k€";
                    } else {
                      return value + " €";
                    }
                  }
                : function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0).toFixed(2);
                  },
              fontSize: 40,
              color: "#000",
            },
          },
        },
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 10,
          },
          value: {
            show: true,
            fontSize: "16px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            color: undefined,
            offsetY: 16,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    noData: {
      text: t("Data_Explorer.select_filters"),
    },
    colors: [
      "#0A52A7",
      "#3DCC4E",
      "#FEC633",
      "#D52DB7",
      "#FC4F00",
      "#ECF2FF",
      "#D3756B",
      "#FF55BB",
      "#393E46",
    ],
  };

  // starting for for line chart
  const LineChartSeries =
    barChartData?.SeriesData?.length == undefined
      ? []
      : barChartData?.DepartmentName?.map((item, i) => {
          return {
            name: item,
            data: barChartData?.SeriesData[i],
          };
        });
  // line chart series for without group_by response.
  const LineChartSeriesForGroupBy = [
    {
      name: "",
      data: barChartData?.SeriesData,
    },
  ];

  const lineChartOptions = {
    markers: {
      size: 0,
    },
    chart: {
      height: "100%",
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
          pan: false,
          selection: false,
          zoom: false,
        },
      },
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      row: {
        // colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        // opacity: 0.5,
      },
    },
    xaxis: {
      type: "category",
      categories: barChartData?.CategoryName?.map((item) => item),
      labels: {
        show: true,
        rotate: -65,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: 190,
        maxHeight: 120,
      },
    },
    // noData: {
    //   text: t("Data_Explorer.select_filters"),
    //   align: "center",
    //   verticalAlign: "middle",
    //   offsetX: 0,
    //   offsetY: 0,
    //   style: {
    //     color: undefined,
    //     fontSize: "14px",
    //     fontFamily: undefined,
    //   },
    //   yaxis: {
    //     lines: {
    //       show: false, //or just here to disable only y axis
    //     },
    //   },
    // },
    colors: [
      "#0A52A7",
      "#3DCC4E",
      "#FEC633",
      "#D52DB7",
      "#FC4F00",
      "#ECF2FF",
      "#D3756B",
      "#FF55BB",
      "#393E46",
    ],
  };
  // for bar chart
  // starting for line chart
  let barChart = barChartData?.chartDataforBar;
  let barChartPecentageData = barChartData?.chartDataforBarInPercentage;

  let chart;
  // function for Vertical bar chart with group value for absolute values
  function barChartVertical() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);

    //Custom colors

    chart.colors.list = [
      am4core.color("#0A52A7"),
      am4core.color("#3DCC4E"),
      am4core.color("#FEC633"),
      am4core.color("#D52DB7"),
      am4core.color("#FC4F00"),
      am4core.color("#ECF2FF"),
      am4core.color("#D3756B"),
      am4core.color("#FF55BB"),
      am4core.color("#393E46"),
    ];

    // custom circular legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(25, 25, 25, 25);
    marker.strokeWidth = 1;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChart;
    //currency format for compensation
    viewData?.metric === "Compensation"
      ? (chart.numberFormatter.numberFormat = "#.#a") // this is to remove decimal point
      : (chart.numberFormatter.numberFormat = "#");

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.minGridDistance = 12;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;

    // Configure axis label
    var label = categoryAxis.renderer.labels.template;
    label.truncate = false;
    label.maxWidth = 200;
    label.tooltipText = "{year}";
    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -60;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    // Create series
    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "year";
      series.sequencedInterpolation = true;
      series.orientation = "horizontal";
      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:18px]{categoryX}: {valueY}";

      // Add label

      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;
      labelBullet.label.fontSize = 16;
      labelBullet.label.fill = am4core.color("#fff");

      return series;
    }

    // improving perfomance
    chart.svgContainer.autoResize = false;
    barChartData?.DepartmentName?.map((item, i) => {
      createSeries(item, item);
    });
    am4core.addLicense("ch-custom-attribution");
  }
  //function for bar chart when group by is empty or not selected for absolute values
  function barChartVerticalForGroupBy() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);

    //Custom colors

    // chart.colors.list = [
    //   am4core.color("#0A52A7"),
    //   am4core.color("#3DCC4E"),
    //   am4core.color("#FEC633"),
    //   am4core.color("#D52DB7"),
    //   am4core.color("#FC4F00"),
    //   am4core.color("#ECF2FF"),
    //   am4core.color("#D3756B"),
    //   am4core.color("#FF55BB"),
    //   am4core.color("#393E46"),
    // ];

    // Add data
    chart.data = barChart;
    //currency format for compensation
    viewData?.metric === "Compensation"
      ? (chart.numberFormatter.numberFormat = "#.#a") // this is to remove decimal point
      : (chart.numberFormatter.numberFormat = "#");
    // this is to remove decimal point
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.minGridDistance = 12;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;

    // Configure axis label
    var label = categoryAxis.renderer.labels.template;
    label.truncate = false;
    label.maxWidth = 200;
    label.tooltipText = "{year}";
    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -60;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    // improving perfomance
    chart.svgContainer.autoResize = false;

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "year";
    series.sequencedInterpolation = true;
    series.orientation = "horizontal";
    series.columns.template.width = am4core.percent(60);
    series.columns.template.tooltipText =
      "[bold]{name}[/]\n[font-size:18px]{categoryX}: {valueY}";
    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.locationY = 0.5;
    labelBullet.label.hideOversized = true;
    labelBullet.label.fontSize = 16;
    labelBullet.label.fill = am4core.color("#fff");
    am4core.addLicense("ch-custom-attribution");
  }

  function barChartVerticalForGroupByAndAttritionPercentage() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);

    //Custom colors

    // chart.colors.list = [
    //   am4core.color("#0A52A7"),
    //   am4core.color("#3DCC4E"),
    //   am4core.color("#FEC633"),
    //   am4core.color("#D52DB7"),
    //   am4core.color("#FC4F00"),
    //   am4core.color("#ECF2FF"),
    //   am4core.color("#D3756B"),
    //   am4core.color("#FF55BB"),
    //   am4core.color("#393E46"),
    // ];

    // Add data
    chart.data = barChart;

    chart.numberFormatter.numberFormat = "#.00" + 'p'; // this is to remove decimal point
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.minGridDistance = 12;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;

    // Configure axis label
    var label = categoryAxis.renderer.labels.template;
    label.truncate = false;
    label.maxWidth = 200;
    label.tooltipText = "{year}";
    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -60;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    // improving perfomance
    chart.svgContainer.autoResize = false;

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "year";
    series.sequencedInterpolation = true;
    series.orientation = "horizontal";
    series.columns.template.width = am4core.percent(60);
    series.columns.template.tooltipText =
      "[bold]{name}[/]\n[font-size:18px]{categoryX}: {valueY}";
    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.locationY = 0.5;
    labelBullet.label.hideOversized = true;
    labelBullet.label.fontSize = 16;
    labelBullet.label.fill = am4core.color("#fff");
    am4core.addLicense("ch-custom-attribution");
  }
  //function for bar chart when group by is empty or not selected for percentage values
  function barChartVerticalWithPercentagevalForGroupBy() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);

    //Custom colors

    // chart.colors.list = [
    //   am4core.color("#0A52A7"),
    //   am4core.color("#3DCC4E"),
    //   am4core.color("#FEC633"),
    //   am4core.color("#D52DB7"),
    //   am4core.color("#FC4F00"),
    //   am4core.color("#ECF2FF"),
    //   am4core.color("#D3756B"),
    //   am4core.color("#FF55BB"),
    //   am4core.color("#393E46"),
    // ];

    // custom circular legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.useDefaultMarker = true;
    // let marker = chart.legend.markers.template.children.getIndex(0);
    // marker.cornerRadius(25, 25, 25, 25);
    // marker.strokeWidth = 1;
    // marker.strokeOpacity = 1;
    // marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChartPecentageData;
    chart.numberFormatter.numberFormat = "#.#a%"; // this is to remove decimal point
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.minGridDistance = 12;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;

    // Configure axis label
    var label = categoryAxis.renderer.labels.template;
    label.truncate = false;
    label.maxWidth = 200;
    label.tooltipText = "{year}";
    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -60;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    // improving perfomance
    chart.svgContainer.autoResize = false;

    // Create series

    // Set up series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.name = "value";
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "year";
    series.sequencedInterpolation = true;
    series.orientation = "horizontal";

    // Configure columns
    series.columns.template.width = am4core.percent(60);
    series.columns.template.tooltipText =
      "[bold]{name}[/]\n[font-size:18px]{categoryX}: {valueY}";

    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    // Add label
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.locationY = 0.5;
    labelBullet.label.hideOversized = true;
    labelBullet.label.fontSize = 16;
    labelBullet.label.fill = am4core.color("#fff");
    am4core.addLicense("ch-custom-attribution");
  }

  //When x-axis is department and groupby value is selected.
  function barChartVerticalWithPercentageval() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);

    //Custom colors

    chart.colors.list = [
      am4core.color("#0A52A7"),
      am4core.color("#3DCC4E"),
      am4core.color("#FEC633"),
      am4core.color("#D52DB7"),
      am4core.color("#FC4F00"),
      am4core.color("#ECF2FF"),
      am4core.color("#D3756B"),
      am4core.color("#FF55BB"),
      am4core.color("#393E46"),
    ];

    // custom circular legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(25, 25, 25, 25);
    marker.strokeWidth = 1;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChartPecentageData;
    chart.numberFormatter.numberFormat = "#.#a%"; // this is to remove decimal point
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.minGridDistance = 12;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;

    // Configure axis label
    var label = categoryAxis.renderer.labels.template;
    label.truncate = false;
    label.maxWidth = 200;
    label.tooltipText = "{year}";
    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -60;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    // Create series
    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "year";
      series.sequencedInterpolation = true;
      series.orientation = "horizontal";
      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:18px]{categoryX}: {valueY}";

      // Add label

      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;
      labelBullet.label.fontSize = 16;
      labelBullet.label.fill = am4core.color("#fff");

      return series;
    }

    // improving perfomance
    chart.svgContainer.autoResize = false;
    barChartData?.DepartmentName?.map((item, i) => {
      createSeries(item, item);
    });
    am4core.addLicense("ch-custom-attribution");
  }
  //function for Horizontal bar chart with groupby values
  function barChartHorizontal() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);
    //Custom colors
    chart.colors.list = [
      am4core.color("#0A52A7"),
      am4core.color("#3DCC4E"),
      am4core.color("#FEC633"),
      am4core.color("#D52DB7"),
      am4core.color("#FC4F00"),
      am4core.color("#ECF2FF"),
      am4core.color("#D3756B"),
      am4core.color("#FF55BB"),
      am4core.color("#393E46"),
    ];

    // custom circular legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(25, 25, 25, 25);
    marker.strokeWidth = 1;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChart;
    //currency format for compensation
    viewData?.metric === "Compensation"
      ? (chart.numberFormatter.numberFormat = "#.#a") // this is to remove decimal point
      : (chart.numberFormatter.numberFormat = "#");

    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.opacity = 0;
    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;
    // Create series
    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "year";
      series.sequencedInterpolation = true;
      // Make it stacked
      series.stacked = true;

      // Add label
      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = 0.5;
      labelBullet.label.text = "{valueX}";
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.label.hideOversized = true;
      labelBullet.label.fontSize = 16;
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}";
    }
    barChartData?.DepartmentName?.map((item, i) => {
      createSeries(item, item);
    });
  }

  //When x-axis is department and groupby value is selected.
  function barChartHorizontalWithPercentageVal() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);
    //Custom colors

    chart.colors.list = [
      am4core.color("#0A52A7"),
      am4core.color("#3DCC4E"),
      am4core.color("#FEC633"),
      am4core.color("#D52DB7"),
      am4core.color("#FC4F00"),
      am4core.color("#ECF2FF"),
      am4core.color("#D3756B"),
      am4core.color("#FF55BB"),
      am4core.color("#393E46"),
    ];

    // custom circular legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(25, 25, 25, 25);
    marker.strokeWidth = 1;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChartPecentageData;
    chart.numberFormatter.numberFormat = "#.#a%"; // this is to remove decimal point

    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.opacity = 0;
    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;
    // Create series
    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "year";
      series.sequencedInterpolation = true;
      // Make it stacked
      series.stacked = true;

      // Add label
      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = 0.5;
      labelBullet.label.text = "{valueX}";
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.label.hideOversized = true;
      labelBullet.label.fontSize = 16;
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}%";
    }
    barChartData?.DepartmentName?.map((item, i) => {
      createSeries(item, item);
    });
  }
  //function for bar chart when group by is empty or not selected
  function barChartHorizontalWithPercentageValForGroupBy() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);
    //Custom colors
    // chart.colors.list = [
    //   am4core.color("#0A52A7"),
    //   am4core.color("#3DCC4E"),
    //   am4core.color("#FEC633"),
    //   am4core.color("#D52DB7"),
    //   am4core.color("#FC4F00"),
    //   am4core.color("#ECF2FF"),
    //   am4core.color("#D3756B"),
    //   am4core.color("#FF55BB"),
    //   am4core.color("#393E46"),
    // ];

    // // custom circular legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.useDefaultMarker = true;
    // let marker = chart.legend.markers.template.children.getIndex(0);
    // marker.cornerRadius(25, 25, 25, 25);
    // marker.strokeWidth = 1;
    // marker.strokeOpacity = 1;
    // marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChartPecentageData;
    chart.numberFormatter.numberFormat = "#.#a%"; // this is to remove decimal point

    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.opacity = 0;
    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;
    // Create series

    // Set up series
    var series = chart.series.push(new am4charts.ColumnSeries());
    // series.name = name;
    series.dataFields.valueX = "value";
    series.dataFields.categoryY = "year";
    series.sequencedInterpolation = true;
    // Make it stacked
    series.stacked = true;

    // Add label
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.locationX = 0.5;
    labelBullet.label.text = "{valueX}";
    labelBullet.label.fill = am4core.color("#fff");
    labelBullet.label.hideOversized = true;
    labelBullet.label.fontSize = 16;
    series.columns.template.tooltipText =
      "[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}";
    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });
  }
  //function for Vertical bar chart when X_Axis is department and group by is selected.
  function barChartVerticalForDepartment() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);

    //Custom colors

    chart.colors.list = [
      am4core.color("#0A52A7"),
      am4core.color("#3DCC4E"),
      am4core.color("#FEC633"),
      am4core.color("#D52DB7"),
      am4core.color("#FC4F00"),
      am4core.color("#ECF2FF"),
      am4core.color("#D3756B"),
      am4core.color("#FF55BB"),
      am4core.color("#393E46"),
    ];

    // custom circular legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(25, 25, 25, 25);
    marker.strokeWidth = 1;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChart;
    chart.numberFormatter.numberFormat = "#.#a"; // this is to remove decimal point
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.minGridDistance = 12;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;

    // Configure axis label
    var label = categoryAxis.renderer.labels.template;
    label.truncate = false;
    label.maxWidth = 200;
    label.tooltipText = "{year}";
    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -60;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    // Create series
    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "year";
      series.sequencedInterpolation = true;
      series.orientation = "horizontal";
      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:18px]{categoryX}: {valueY}";

      // Add label

      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;
      labelBullet.label.fontSize = 16;
      labelBullet.label.fill = am4core.color("#fff");

      return series;
    }

    // improving perfomance
    chart.svgContainer.autoResize = false;
    barChartData?.DepartmentName?.map((item, i) => {
      createSeries(item, item);
    });
    am4core.addLicense("ch-custom-attribution");
  }
  function barChartVerticalForAttritionPercentage() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);

    //Custom colors

    chart.colors.list = [
      am4core.color("#0A52A7"),
      am4core.color("#3DCC4E"),
      am4core.color("#FEC633"),
      am4core.color("#D52DB7"),
      am4core.color("#FC4F00"),
      am4core.color("#ECF2FF"),
      am4core.color("#D3756B"),
      am4core.color("#FF55BB"),
      am4core.color("#393E46"),
    ];

    // custom circular legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(25, 25, 25, 25);
    marker.strokeWidth = 1;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChart;
    chart.numberFormatter.numberFormat = "#.00" + 'p'; // this is to remove decimal point and add % sign.
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.minGridDistance = 12;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;

    // Configure axis label
    var label = categoryAxis.renderer.labels.template;
    label.truncate = false;
    label.maxWidth = 200;
    label.tooltipText = "{year}";
    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -60;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    // Create series
    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "year";
      series.sequencedInterpolation = true;
      series.orientation = "horizontal";
      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:18px]{categoryX}: {valueY}";

      // Add label

      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;
      labelBullet.label.fontSize = 16;
      labelBullet.label.fill = am4core.color("#fff");

      return series;
    }

    // improving perfomance
    chart.svgContainer.autoResize = false;
    barChartData?.DepartmentName?.map((item, i) => {
      createSeries(item, item);
    });
    am4core.addLicense("ch-custom-attribution");
  }

  //Function for horizontal bar chart when x-axis is not selected as department and groupby is selected.
  function barChartHorizontalWithPercentageValForDepartment() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);

    //Custom colors
    chart.colors.list = [
      am4core.color("#0A52A7"),
      am4core.color("#3DCC4E"),
      am4core.color("#FEC633"),
      am4core.color("#D52DB7"),
      am4core.color("#FC4F00"),
      am4core.color("#ECF2FF"),
      am4core.color("#D3756B"),
      am4core.color("#FF55BB"),
      am4core.color("#393E46"),
    ];

    // custom circular legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(25, 25, 25, 25);
    marker.strokeWidth = 1;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChart;
    chart.numberFormatter.numberFormat = "#.#a%"; // this is to remove decimal point

    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.opacity = 0;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;

    // Create series

    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "year";
      series.sequencedInterpolation = true;
      series.calculatePercent = true;
      series.cursorTooltipEnabled = false;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:18px]{categoryY}: [bold]{valueX.percent}%[/] ({valueX})";
      // Configure legend
      series.legendSettings.itemValueText = "{valueX.percent}%";
      // Add label
      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueX.percent}";
      labelBullet.locationX = 0.5;
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.label.hideOversized = true;
      labelBullet.label.fontSize = 16;
      labelBullet.label.adapter.add("textOutput", function (text, target) {
        // Hide labels with 0 value
        if (target.dataItem && target.dataItem.valueX == 0) {
          return "";
        }
        return text;
      });
      return series;
    }

    barChartData?.DepartmentName?.map((item, i) => {
      createSeries(item, item);
    });
  }
  //Function for vertical bar chart when x-axis is not selected as department and groupby is selected.
  function barChartVerticalWithPercentagevalForDepartment() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);

    //Custom colors

    chart.colors.list = [
      am4core.color("#0A52A7"),
      am4core.color("#3DCC4E"),
      am4core.color("#FEC633"),
      am4core.color("#D52DB7"),
      am4core.color("#FC4F00"),
      am4core.color("#ECF2FF"),
      am4core.color("#D3756B"),
      am4core.color("#FF55BB"),
      am4core.color("#393E46"),
    ];

    // custom circular legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(25, 25, 25, 25);
    marker.strokeWidth = 1;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChart;
    chart.numberFormatter.numberFormat = "#.#a%"; // this is to remove decimal point
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.minGridDistance = 12;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;

    // Configure axis label
    var label = categoryAxis.renderer.labels.template;
    label.truncate = false;
    label.maxWidth = 200;
    label.tooltipText = "{year}";

    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -60;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });
    // Create series for percentages
    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "year";
      series.sequencedInterpolation = true;
      series.calculatePercent = true;
      series.cursorTooltipEnabled = false;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:18px]{categoryX}: [bold]{valueY.percent}%[/] ({valueY})";

      // Configure legend
      series.legendSettings.itemValueText = "{valueY.percent}%";

      // Add label
      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY.percent}";
      labelBullet.locationY = 0.5;
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.label.hideOversized = true;
      labelBullet.label.fontSize = 16;

      labelBullet.label.adapter.add("textOutput", function (text, target) {
        // Hide labels with 0 value
        if (target.dataItem && target.dataItem.valueY == 0) {
          return "";
        }
        return text;
      });
      return series;
    }
    // improving perfomance
    chart.svgContainer.autoResize = false;
    barChartData?.DepartmentName?.map((item, i) => {
      createSeries(item, item);
    });
    am4core.addLicense("ch-custom-attribution");
  }
  //function for bar chart when group by is empty or not selected.
  function barChartHorizontalForGroupBy() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);
    //Custom colors
    // chart.colors.list = [
    //   am4core.color("#0A52A7"),
    //   am4core.color("#3DCC4E"),
    //   am4core.color("#FEC633"),
    //   am4core.color("#D52DB7"),
    //   am4core.color("#FC4F00"),
    //   am4core.color("#ECF2FF"),
    //   am4core.color("#D3756B"),
    //   am4core.color("#FF55BB"),
    //   am4core.color("#393E46"),
    // ];

    // custom circular legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.useDefaultMarker = true;
    // let marker = chart.legend.markers.template.children.getIndex(0);
    // marker.cornerRadius(25, 25, 25, 25);
    // marker.strokeWidth = 1;
    // marker.strokeOpacity = 1;
    // marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChart;
    //currency format for compensation
    viewData?.metric === "Compensation"
      ? (chart.numberFormatter.numberFormat = "#.#a") // this is to remove decimal point
      : (chart.numberFormatter.numberFormat = "#");

    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.opacity = 0;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;
    // Create series

    // Set up series
    var series = chart.series.push(new am4charts.ColumnSeries());
    // series.name = "value";
    series.dataFields.valueX = "value";
    series.dataFields.categoryY = "year";
    series.sequencedInterpolation = true;

    // Add label
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.locationX = 0.5;
    labelBullet.label.text = "{valueX}";
    labelBullet.label.fill = am4core.color("#fff");
    labelBullet.label.hideOversized = true;
    labelBullet.label.fontSize = 16;
    series.columns.template.tooltipText =
      "[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}";
    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });
  }
  function barChartHorizontalForGroupByAndAttritionPercentage() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);
    //Custom colors
    // chart.colors.list = [
    //   am4core.color("#0A52A7"),
    //   am4core.color("#3DCC4E"),
    //   am4core.color("#FEC633"),
    //   am4core.color("#D52DB7"),
    //   am4core.color("#FC4F00"),
    //   am4core.color("#ECF2FF"),
    //   am4core.color("#D3756B"),
    //   am4core.color("#FF55BB"),
    //   am4core.color("#393E46"),
    // ];

    // custom circular legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.useDefaultMarker = true;
    // let marker = chart.legend.markers.template.children.getIndex(0);
    // marker.cornerRadius(25, 25, 25, 25);
    // marker.strokeWidth = 1;
    // marker.strokeOpacity = 1;
    // marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChart;
    chart.numberFormatter.numberFormat =  "#.00" + 'p'; // this is to remove decimal point and add % sign
    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.opacity = 0;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;
    // Create series

    // Set up series
    var series = chart.series.push(new am4charts.ColumnSeries());
    // series.name = "value";
    series.dataFields.valueX = "value";
    series.dataFields.categoryY = "year";
    series.sequencedInterpolation = true;

    // Add label
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.locationX = 0.5;
    labelBullet.label.text = "{valueX}";
    labelBullet.label.fill = am4core.color("#fff");
    labelBullet.label.hideOversized = true;
    labelBullet.label.fontSize = 16;
    series.columns.template.tooltipText =
      "[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}";
    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });
  }
  //function for Horizontal bar chart when X_Axis is department and groupby is selected.
  function barChartHorizontalForDepartment() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);
    //Custom colors
    chart.colors.list = [
      am4core.color("#0A52A7"),
      am4core.color("#3DCC4E"),
      am4core.color("#FEC633"),
      am4core.color("#D52DB7"),
      am4core.color("#FC4F00"),
      am4core.color("#ECF2FF"),
      am4core.color("#D3756B"),
      am4core.color("#FF55BB"),
      am4core.color("#393E46"),
    ];

    // custom circular legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(25, 25, 25, 25);
    marker.strokeWidth = 1;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChart;
    chart.numberFormatter.numberFormat = "#.#a"; // this is to remove decimal point
    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.opacity = 0;
    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;
    // Create series
    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "year";
      series.sequencedInterpolation = true;
      // Make it stacked
      series.stacked = true;

      // Add label
      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = 0.5;
      labelBullet.label.text = "{valueX}";
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.label.hideOversized = true;
      labelBullet.label.fontSize = 16;
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}";
    }
    barChartData?.DepartmentName?.map((item, i) => {
      createSeries(item, item);
    });
  }
  function barChartHorizontalForAttritionPercentage() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart instance
    chart = am4core.create("barChartDataExplorer", am4charts.XYChart);
    //Custom colors
    chart.colors.list = [
      am4core.color("#0A52A7"),
      am4core.color("#3DCC4E"),
      am4core.color("#FEC633"),
      am4core.color("#D52DB7"),
      am4core.color("#FC4F00"),
      am4core.color("#ECF2FF"),
      am4core.color("#D3756B"),
      am4core.color("#FF55BB"),
      am4core.color("#393E46"),
    ];

    // custom circular legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(25, 25, 25, 25);
    marker.strokeWidth = 1;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#fff");

    // Add data
    chart.data = barChart;
    chart.numberFormatter.numberFormat = "#.00" + 'p'; // this is to remove decimal point and add % sign
    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.opacity = 0;
    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 40;
    // Create series
    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "year";
      series.sequencedInterpolation = true;
      // Make it stacked
      series.stacked = true;

      // Add label
      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = 0.5;
      labelBullet.label.text = "{valueX}";
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.label.hideOversized = true;
      labelBullet.label.fontSize = 16;
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}";
    }
    barChartData?.DepartmentName?.map((item, i) => {
      createSeries(item, item);
    });
  }
  useEffect(() => {
    if (Orientation) {
      if (!valueFormat) {
        if (!groupby) {
          barChartHorizontalWithPercentageValForGroupBy();
        } else {
          if (viewData?.X_Axis === "Department") {
            // barChartHorizontalWithPercentageValForDepartment();
            barChartHorizontalWithPercentageVal();
          } else {
            barChartHorizontalWithPercentageVal();
            // barChartHorizontalWithPercentageValForDepartment();
          }
        }
      } else {
        if (!groupby) {
          if (viewData?.attritionMetrics) {
            barChartHorizontalForGroupByAndAttritionPercentage();
          } else {
            barChartHorizontalForGroupBy();
          }
        } else {
          // if (X_Axis === "Department") {
          //   barChartHorizontalForDepartment();
          // } else {
          //   barChartHorizontal();
          // }
          if (viewData?.attritionMetrics) {
            barChartHorizontalForAttritionPercentage();
          } else {
            barChartHorizontal();
          }
        }
      }
    } else {
      if (!valueFormat) {
        if (!groupby) {
          barChartVerticalWithPercentagevalForGroupBy();
        } else {
          if (X_Axis === "Department") {
            barChartVerticalWithPercentageval();
          } else {
            // barChartVerticalWithPercentagevalForDepartment();
            barChartVerticalWithPercentageval();
          }
        }
      } else {
        if (!groupby) {
          if (viewData?.attritionMetrics) {
            barChartVerticalForGroupByAndAttritionPercentage();
          } else {
            barChartVerticalForGroupBy();
          }
        } else {
          // if (X_Axis === "Department") {
          //   barChartVerticalForDepartment();
          // } else {
          //   barChartVertical();
          // }
          if (viewData?.attritionMetrics) {
            barChartVerticalForAttritionPercentage();
          } else {
            barChartVertical();
          }
        }
      }
    }
    return () => chart.dispose();
  }, [barChartData, Orientation, valueFormat, groupby, X_Axis]);
  const navigate = useNavigate();

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Header
          heading={
            location === "myreports"
              ? t("Report.title")
              : t("Report.Shared_With_Me")
          }
          subHeading={t("Report.sub_title")}
        />
      </Suspense>
      <div className="flex items-center my-10 text-gray-400">
        <IoIosArrowRoundBack className="w-12 text-2xl" />
        <button onClick={() => navigate(-1)}>Back to the list</button>
      </div>

      <div className="px-5 py-5 container-custom">
        <div className="flex flex-col gap-2">
          <div className="flex items-end justify-end ml-2">
            <div className="bg-white mb-4 py-6 flex items-center justify-center rounded-md w-[75%]">
              <h6 className="font-semibold text-lg font-poppins">
                {viewData?.reportTitle}
              </h6>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="bg-white rounded-[20px] flex flex-col items-center py-4 w-[25%]">
              <h2 className="font-semibold py-4 border-b-[1px] w-full text-center text-xl">
                {t("Data_Explorer.filters")}
              </h2>

              <div className="mt-6 pb-10 px-4 border-b-[1px] w-full flex flex-col items-start justify-center ">
                <h4 className="font-semibold text-lg mb-3">
                  {t("AdvancedAnalytics.period")}
                </h4>
                <p className="text-xs  mt-2">
                  from{" "}
                  <span className="bg-gray-200 p-1 rounded-[2px]">
                    {newDate}
                  </span>{" "}
                  to{" "}
                  <span className="bg-gray-200 p-1 rounded-[2px]">
                    {currentDate}
                  </span>
                </p>
              </div>
              <div className="mt-6 px-4  w-full flex flex-col items-start justify-center ">
                <h4 className="font-semibold text-lg mb-3">
                  {t("userManagement.PeopleScope")}
                </h4>
                <div className="my-4">
                  <p className="font-semibold mb-2">{t("global.entity")}</p>
                  <div className="flex gap-2">
                    {entity?.map((entity, i) => {
                      return (
                        <div key={i}>
                          <input type="checkbox" checked={true} /> {entity}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-[32rem]">
                  <p className="font-semibold my-4 w-full">
                    {t("global.department")}
                  </p>
                  <div className="mb-2 grid grid-cols-2 gap-3 w-full">
                    <div className="flex gap-2 flex-wrap w-[80%]">
                      {department?.map((department, i) => {
                        return (
                          <div key={i}>
                            <input type="checkbox" checked={true} />{" "}
                            {department}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-[20px] w-[75%]  flex items-center justify-center pt-8">
              {viewData?.chartType === "bar" && (
                <div
                  id="barChartDataExplorer"
                  className="w-full h-[70vh] min-h-[550px]  mb-5"
                ></div>
              )}
              {viewData?.chartType === "line" && (
                <div className="w-[95%] ">
                  {viewData?.chartType === "line" &&
                    !barChartData?.isGroupBy && (
                      <ReactApexChart
                        options={lineChartOptions}
                        series={LineChartSeries}
                        type="line"
                        width={"100%"}
                        height={600}
                      />
                    )}
                  {barChartData?.isGroupBy && (
                    <ReactApexChart
                      options={lineChartOptions}
                      series={LineChartSeriesForGroupBy}
                      type="line"
                      height={600}
                    />
                  )}
                </div>
              )}
              {viewData?.chartType === "pie" &&
                (pieChartData?.values?.length !== 0 ? (
                  <div className="self-center  flex items-center justify-center w-full h-[100%] ">
                    <div className="grow flex flex-col justify-between items-center w-full ">
                      <div className="w-[95%]">
                        {valueFormat &&
                          (showValueFormatterPie ? (
                            <ReactApexChart
                              options={pieChartOptions}
                              series={pieChartSeries}
                              type="donut"
                            />
                          ) : (
                            <>
                              <ReactApexChart
                                options={pieChartOptionsForHideTotal}
                                series={pieChartSeries}
                                type="donut"
                              />
                            </>
                          ))}
                        {!valueFormat && (
                          <ReactApexChart
                            options={pieChartOptionsForPercentageData}
                            series={pieChartSeries}
                            type="donut"
                          />
                        )}

                        {/* <Chart
                          options={pieChartOptions}
                          series={pieChartSeries}
                          type="donut"
                        /> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center flex items-center justify-center h-full">
                    No Data Available.
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewReport;
