import { useMutation, useQuery } from "react-query";
import {
  getDataForDataExplorer,
  getFilterListingForDataExplorer,getDataForDataExplorerPieChart
} from "../api/dataExplorer";
import { toast } from "react-toastify";

export const useFliterListingForDataExplorer = () => {
  const { data, isLoading, error, refetch } = useQuery(
    ["filter-listing-dataExplorer"],
    getFilterListingForDataExplorer,
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 500000,
    }
  );
  return { data, isLoading, error, refetch };
};

export const useDataForDataExplorer = () => {
  // const queryClient = useQueryClient();
  const { data, isLoading, error, mutate,status } = useMutation(
    (body) => getDataForDataExplorer(body),
    
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 50000,
    }
  );
  return { data, isLoading, error, mutate,status };
};


export const useDataForDataExplorerPieChart = () => {
  // const queryClient = useQueryClient();
  const { data, isLoading, error, mutate,status } = useMutation(
    (body) => getDataForDataExplorerPieChart(body),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 50000,
    }
  );
  return { data, isLoading, error, mutate,status };
};
