import React from "react";
import { memo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import logo from "../assets/icons/logo.svg";
import useLoginPage from "./useLoginPage";

function ForgotPassword() {
  const { forgotPassword } = useLoginPage()
  const initialValues = {
    email: "",
  };
  const forgotPassSchema = Yup.object({
    email: Yup.string()
      .trim("The email cannot include spaces.")
      .strict(true)
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email must be a valid email."
      )
      .ensure()
      .email("Email must be a valid email.")
      .required("Please enter your email."),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: forgotPassSchema,
    onSubmit: (values, action) => {
      console.log(values);
      forgotPassword({ email: values.email })
      action.resetForm();
    },
  });
  return (
    <>
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-gray-200">
        <div>
          <div className="flex justify-center items-center w-full my-5">
            <img src={logo} alt="staffDeskLogo" className="w-[200px]" />

          </div>
          <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
            <h1 className="text-2xl font-semibold text-center text-blue-600 ">
              Forgot Password
            </h1>
            <form className="mt-6" onSubmit={handleSubmit}>
              <div className="mb-2">
                <label
                  for="email"
                  className="block text-sm font-semibold text-gray-800"
                >
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
                {errors.email && touched.email ? (
                  <p className="text-red-600 text-sm">{errors.email}</p>
                ) : null}
              </div>

              <div className="mt-6">
                <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-600 rounded-md hover:text-blue-500 focus:outline-none focus:text-blue-500">
                  Send Reset Code
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(ForgotPassword);
