import { useMutation } from "react-query";
import {
  getDataForBasicAdvAnalytics,
  getDataForGoFurtherAdvAnalytics,
  getDataForSummaryAdvAnalytics,
} from "../api/advancedAnalytics";

export const useDataForBasicAdvAnalytics = () => {
  // const queryClient = useQueryClient();
  const { data, isLoading, error, mutate, status } = useMutation(
    (body) => getDataForBasicAdvAnalytics(body),

    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 50000,
    }
  );
  return { data, isLoading, error, mutate, status };
};

export const useDataForGoFurtherAdvAnalytics = () => {
  // const queryClient = useQueryClient();
  const { data, isLoading, error, mutate, status } = useMutation(
    (body) => getDataForGoFurtherAdvAnalytics(body),

    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 50000,
    }
  );
  return { data, isLoading, error, mutate, status };
};

export const useDataForSummaryAdvAnalytics = () => {
  const { data, isLoading, error, mutate, status } = useMutation(
    (body) => getDataForSummaryAdvAnalytics(body),

    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 50000,
    }
  );
  return { data, isLoading, error, mutate, status };
};

